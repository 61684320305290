// import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
    return localStorage.getItem(TokenKey);
  // return Cookies.get(TokenKey)
}

export function setToken(token) {
    return  localStorage.setItem(TokenKey,token)
  // return Cookies.set(TokenKey, token, 2147483647)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
  // return Cookies.remove(TokenKey)
}

export function setValue(key,val) {
    return  localStorage.setItem(key,val)
    // return Cookies.set(key, val, 2147483647)
}

export function getValue(key) {
    return localStorage.getItem(key);
    // return Cookies.get(key)
}


export function removeValue(key) {
    return localStorage.removeItem(key)
    // return Cookies.remove(key)
}
