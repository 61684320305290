import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login.vue')
        },
        {
            path: '/room',
            name: 'room',
            component: () => import('@/views/room.vue')
        },
        {
            path: '/web',
            name: 'web',
            component: () => import('@/views/web.vue')
        },
        {
            path: '/answer',
            name: 'answer',
            component: () => import('@/views/answer.vue')
        },
        {
            path: '/otherWeb',
            name: 'otherWeb',
            component: () => import('@/views/otherWeb.vue')
        },
        {
            path: '/order',
            name: 'order',
            component: () => import('@/views/order.vue')
        },
        {
            path: '/list',
            name: 'list',
            component: () => import('@/views/list.vue')
        },
        {
            path: '/orderDetail',
            name: 'orderDetail',
            component: () => import('@/views/orderDetail.vue')
        },
        {
            path: '/tousu_orderDetail',
            name: 'tousu_orderDetail',
            component: () => import('@/views/tousu_orderDetail.vue')
        },
        {
            path: '/user_group',
            name: 'user_group',
            component: () => import('@/views/user_group.vue')
        },
        {
            path: '/ranqi_orderDetail',
            name: 'ranqi_orderDetail',
            component: () => import('@/views/ranqi_orderDetail.vue')
        },
        {
            path: '/my',
            name: 'my',
            component: () => import('@/views/my.vue')
        },
        {
            path: '/baidu',
            name: 'baidu',
            component: () => import('@/views/baidu.vue')
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/views/home.vue')
        },
        {
            path: '/site',
            name: 'site',
            component: () => import('@/views/site.vue')
        },
        {
            path: '/network',
            name: 'network',
            component: () => import('@/views/network.vue')
        },
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/home.vue')
        },
        {
            path: '/roomDetail',
            name: 'roomDetail',
            component: () => import('@/views/roomDetail.vue')
        },
        {
            path: '/electricCharge',
            name: 'electricCharge',
            component: () => import('@/views/electricCharge.vue')
        }
    ]
})
