<template>
    <div id="big_box">
        <!--<CommonHeader title="呼叫中心系统" isBack="false"/>-->
        <!-- 中间内容区域  高度自适应 -->
        <div id="sention">
            <!-- bn -->
            <div class="bn"><img src="@/assets/images/img_homebg.png"></div>
            <!-- 菜单 -->
            <div class="swiper">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <a href="https://payapp.weixin.qq.com/life/index?showwxpaytitle=1&from=3rd_cib&__hash=%2Fagency%2F2%2Fcity%2F410100%2Fcompanyid%2Fm20200205100007846#wechat_redirect">
                                <img src="@/assets/images/icon_dljf.png">
                                <span>电力缴费</span>
                            </a>
                            <a href="http://pay.xingganggas.com/xgLogin/index">
                                <img src="@/assets/images/icon_rqjf.png">
                                <span>燃气缴费</span>
                            </a>
                            <a href="http://weixin.hkgcsyy.com/tpl/cxyhbh.php">
                                <img src="@/assets/images/icon_shjf.png">
                                <span>热力缴费</span>
                            </a>
                            <!--<router-link to="/electricCharge">
                                <img src="@/assets/images/icon_dfcx.png">
                                <span>电费查询</span>
                            </router-link>-->
                            <a @click="waitUpdate">
                                <img src="@/assets/images/icon_dfcx.png">
                                <span>电费查询</span>
                            </a>
                            <router-link to="/site">
                                <img src="@/assets/images/icon_yywd.png">
                                <span>营业网点</span>
                            </router-link>
                            <router-link to="/room">
                                <img src="@/assets/images/icon_fjgl.png">
                                <span>房间管理</span>
                            </router-link>
                            <router-link to="/answer">
                                <img src="@/assets/images/icon_wjdc.png">
                                <span>问卷调查</span>
                            </router-link>
                            <router-link to="/otherWeb?title=联系我们&url=http://www.xgtzgysy.com/lxwm/">
                                <img src="@/assets/images/icon_lxkf.png">
                                <span>联系我们</span>
                            </router-link>

                            <router-link to="/order"  v-if="isLogin=='true'">
                                <img src="@/assets/images/icon_gdlb.png">
                                <span>工单列表</span>
                            </router-link>
                            <a href="https://mp.weixin.qq.com/s/z4CdFRBkJ-LCK7eDwLMjYw" target="_Self" v-if="isLogin=='false'">
                                <img src="@/assets/images/icon_gywm.png">
                                <span>关于我们</span>
                            </a>
                            <!--<a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUzMjk2ODY5Mw==&scene=116#wechat_redirect">-->
                            <a href="https://mp.weixin.qq.com/s/z4CdFRBkJ-LCK7eDwLMjYw" target="_Self" v-if="isLogin=='true'">
                                <img src="@/assets/images/icon_gywm.png">
                                <span>关于我们</span>
                            </a>


                            <router-link to="/otherWeb?title=发票下载&url=http://weixin.hkgcsyy.com/tpl/cxyhfp.php">
                                <img src="@/assets/images/icon_fpxz.png">
                                <span>热力发票</span>
                            </router-link>
                            <router-link to="/otherWeb?title=公告新闻&url=http://www.xgtzgysy.com/xwzx/gsxw">
                                <img src="@/assets/images/icon_ggxw.png">
                                <span>公告新闻</span>
                            </router-link>
                            <!--<a @click="waitUpdate">-->
                                <!--<img src="@/assets/images/icon_tdgg.png">-->
                                <!--<span>停电公告</span>-->
                            <!--</a>-->
                            <!-- li不要删除 -->
                            <li></li>
                        </div>
                        <div class="swiper-slide">
                            <!--<a @click="waitUpdate">-->
                                <!--<img src="@/assets/images/icon_xxgk.png">-->
                                <!--<span>信息公开</span>-->
                            <!--</a>-->
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <!-- 最新工单 -->
            <div class="new_order bg-white" v-if="isLogin=='true'">
                <h5>
                    <strong><i></i>最新工单</strong>
                    <router-link to="/order">更多</router-link>
                </h5>

                <div class="list mt-4">
                    <div class="no_data" v-if="list.length == 0">
                        <img src="@/assets/images/no_data.png">
                        <span>暂无数据</span>
                    </div>
                    <a v-for="item in list" :key="item.CASEID" @click="openDetail(item.CASEID,item.CASETYPE)">
                        <div v-bind:class="item.STATUS == 1 ? 'state dai' : (item.STATUS == 2 ? 'state jing' : (item.STATUS == 3 ? 'state zhuan' : 'state yi'))">
                            <span>{{item.STATUS == 1 ? '待' : (item.STATUS == 2 ? '进' : (item.STATUS == 3 ? '转' : '已'))}}</span></div>
                        <div class="text">
                            <p>{{item.CASENAME}}</p>
                            <small>工单号：{{item.CASEID}}</small>
                            <!--<small>派单人工号：{{item.GHID_DISPATCH}}</small>-->
                            <small>派单人姓名：{{item.GHID_DIS_NAME}}</small>
                            <!--<small>地址：{{item.ADDRESS}}</small>-->
                            <small>工单时间：{{item.SDATE | formatDate}} {{item.STIME | formatTime}}</small>
                        </div>
                    </a>

                </div>
            </div>
        </div>

        <!-- footer -->
        <div id="footer" class="bg-white border-top">
            <router-link to="/home?fromLogin=1" class="home active">
                <i></i>
                <small>首页</small>
            </router-link>
            <router-link to="/order" class="order" v-if="isLogin=='true'">
                <i></i>
                <small>工单</small>
            </router-link>
            <router-link to="/my" class="user">
                <i></i>
                <small>我的</small>
            </router-link>
        </div>
    </div>

</template>

<script>
//    import CommonHeader from '../components/CommonHeader.vue'
//    import Swiper from '@/assets/js/swiper-bundle.min.js'
    import { login, getOpenid } from "@/api/login";
    import { setValue, getValue } from '@/utils/auth';
    import { getList } from "@/api/order";

    export default {
        name: "home",
        components: {
//            CommonHeader
        },
        data () {
            return {
                isLogin: getValue('isLogin')== undefined ? false : getValue('isLogin'),
                ghid: getValue('ghid'),
                keyword: '',
                token: getValue('token'),
                status: '',
                pageNo: 1,
                pageSize: 5,
                list: [],
                openid: getValue('openid'),
                code: '',
                fromLogin: 0,
                pwd: getValue('pwd'),
                headImg: getValue('headImg'),
            }
        },
        mounted() {
//            new Swiper('.swiper-container', {
//                pagination: {
//                    el: '.swiper-pagination',
//                },
//            });
            this.code = this.getUrlCode().code; // 截取code
            console.log('this.code='+this.code);
            console.log('this.isLogin='+this.isLogin);
            if (!this.isLogin && this.code != undefined && this.code != null && this.code != '') { // 如果没有code，则去请求
                getOpenid({code: this.code}).then(res =>{
                    console.log(res);
                    if(res.errcode != 40163) {
                        this.openid = res.openid;
                        this.headImg = res.headimgurl;
                        setValue('headImg', this.headImg);
                        this.login();
                    }
                });
            }else {
                this.fromLogin = this.$router.history.current.query.fromLogin;
                console.log('this.fromLogin='+this.fromLogin)
                if(this.isLogin) {
                    this.getList()
                }
            }
        },
        methods: {
            //获取列表
            getList() {
                var data = {
                    ghid: this.ghid,
                    keyword: this.keyword,
                    token: this.token,
                    status: this.status,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }
                console.log("开始获取工单列表pageNo..."+this.pageNo)
                getList(data).then(res =>{
                    if(res.code == 1) {
                        this.list = res.data;
                    }
                });
            },
            login() {

                var data = {
                    ghid: this.ghid,
                    pwd: this.pwd,
                    openid: this.openid,
                    headImg: this.headImg
                }
                login(data).then(res =>{
                    if(res.code == 1) {
                        setValue('token', res.token);
                        setValue('ghid', this.ghid);
                        setValue('pwd', this.pwd);
                        setValue('openid', this.openid);
                        setValue('isLogin', true);
                        setValue('userType', 'user');//当userType为user时，代表使用人员，为youke时代表普通游客
                        this.getList()
                    }
                });
            },
            //工单详情页面
            openDetail(CASEID,CASETYPE) {
                var that = this;
                console.log('id==='+CASEID)
                if(CASETYPE == 13) {//投诉工单
                    this.$router.push({
                        name:'tousu_orderDetail',
                        query: {
                            CASEID: CASEID,
                            CASETYPE: CASETYPE,
                            fromHome: 1,
                        }
                    })
                }else if(CASETYPE == 17 || CASETYPE == 18 || CASETYPE == 19 || CASETYPE == 20) {//燃气工单
                    that.$router.push({
                        name: 'ranqi_orderDetail',
                        query: {
                            CASEID: CASEID,
                            CASETYPE: CASETYPE,
                            status: that.status,
                            fromHome: 0,
                            keyword: that.keyword,
                        }
                    })
                }else {
                    this.$router.push({
                        name:'orderDetail',
                        query: {
                            CASEID: CASEID,
                            CASETYPE: CASETYPE,
                            fromHome: 1,
                        }
                    })
                }

            },
            //等待升级
            waitUpdate() {
                this.$toast({
                    text: '请等待后续升级！',
                    type: 'wraning',
                    duration: 1500
                })
            },
            showToast () {
                this.$toast({
                    text: '我是消息',
                    type: 'success',
                    duration: 3000
                })
            },
            handleAlert() {
                this.$alert('测试')
            },
            handleConfirm() {
                this.$confirm('测试Confirm', () => {
                    console.log('这是确定事件');
                }, () => {
                    console.log('这是取消事件');
                })
            },
            // 截取url中的code方法
            getUrlCode () {
                var url = location.search
                console.log('url=='+url)
                this.winUrl = url
                var theRequest = new Object()
                if (url.indexOf('?') != -1 ) {
                    var str = url.substr(1)
                    var strs = str.split('&')
                    for (var i = 0; i < strs.length; i++) {
                        theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
                    }
                }
                return theRequest
            }
        },
        filters: {
            formatDate: function (value) {
                return value.substring(0,4)+'-'+value.substring(4,6)+'-'+value.substring(6,8)
            },

            formatTime: function (value) {
                return value.substring(0,2)+':'+value.substring(2,4)+':'+value.substring(4,6)
            }
        }
    }
</script>
<style>

    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
	
	/* html{
		-webkit-filter:grayscale(100%);
		-moz-filter:grayscale(100%);
		-ms-filter:grayscale(100%);
		-o-filter:grayscale(100%);
		filter:grayscale(100%);
		filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)
	}
	*{
		-moz-filter: grayscale(100%); 
		-ms-filter: grayscale(100%); 
		-o-filter: grayscale(100%); 
		filter: gray;
	} */

</style>