<!-- header -->
<template>
	<div id="big_box">
		<CommonHeader title="工单详情" isBack="1" />
		<!-- 中间内容区域  高度自适应 -->
		<div id="sention">
			<!-- 预约工单 -->
			<div class="order_box bg-white" v-if="item.CASETYPE != 19">
				<h5>
					<i class="fwnr"></i>
					<span>客户信息</span>
				</h5>
				<ul>
					<li>
						<span class="t">用户号</span>
						<span class="c">{{item.USERNO}}</span>
					</li>
					<li>
						<span class="t">客户名称</span>
						<span class="c">{{item.UNAME}}</span>
					</li>
					<li class="tel">
						<span class="t">主叫号码</span>
						<span class="c" @click="tel(item.CALLER)">{{item.CALLER}}</span>
						<button v-clipboard:copy="item.CALLER" v-clipboard:success="onCopy" v-clipboard:error="onError">
							<img src="@/assets/images/icon_copy.png">
						</button>
					</li>
					<li class="textarea up">
						<span class="t">地址</span>
						<textarea v-model="item.ADDRDES" disabled="disabled"></textarea>
					</li>
				</ul>
			</div>
			<!-- 服务内容 -->
			<!-- 任务单详情 -->
			<div class="order_box bg-white" v-if="item.CASETYPE == 17">
				<h5>
					<i class="rwd"></i>
					<span>工单信息</span>
				</h5>
				<ul>
					<li>
						<span class="t">工单名称</span>
                        <span class="c">{{item.CASENAME}}</span>
					</li>
					<li>
						<span class="t">工单单号</span>
						<span class="c">{{item.CASEID}}</span>
					</li>
					<li>
						<span class="t">工单状态</span>
						<span class="c">{{statusdes}}</span>
					</li>
					<li>
						<span class="t">派单人姓名</span>
						<span class="c">{{item.GHID_DIS_NAME}}</span>
					</li>
					<li>
						<span class="t">派单日期</span>
						<span class="c">{{item.DISPATCH_DAT}}</span>
					</li>
                    <li class="daka">
                        <span class="t">打卡地址</span>
                        <span class="c">{{item.PUNCH_ADDR == null || item.PUNCH_ADDR == '' ? address : item.PUNCH_ADDR}}</span>
                        <!--<span class="c">{{item.ADDRESS}}</span>-->
                        <button @click="cardClick" v-if="item.STATUS != 5"><img src="@/assets/images/icon_lct.png">
                        </button>
                    </li>
					<li>
						<span class="t">紧急程度</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.LEVEL" :disabled="item.STATUS==1 || true">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in levelList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
                    <li class="textarea up">
                        <span class="t">问题描述</span>
                        <textarea v-model="item.PROBLEM_DES" placeholder="请输入问题描述"
                                  :disabled="item.STATUS==1 || true"></textarea>
                    </li>
					<li>
						<span class="t">是否合格</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.IS_QUALIFIED" :disabled="item.STATUS==1">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in hegeList" v-bind:key="it" :value="it">{{it}}
								</option>
							</select>
						</span>
					</li>
                    <li :class="item.IS_QUALIFIED == '不合格' ? 'star textarea up' : 'textarea up'">
                        <span class="t">不合格原因</span>
                        <textarea v-model="item.UN_QUALIFIED_REASON" placeholder="请输入不合格原因"
                                  :disabled="item.STATUS==1"></textarea>
                    </li>
					<li>
						<span class="t">无法施工类型</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.UNBUILD_TYPE" :disabled="item.STATUS==1">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in sgTypeList17" v-bind:key="it" :value="it">{{it}}
								</option>
							</select>
						</span>
					</li>
					<li class="star">
						<span class="t">处理结果</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.PROCESS_RESULT" :disabled="item.STATUS==1">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in resultList" v-bind:key="it" :value="it">{{it}}
								</option>
							</select>
						</span>
					</li>
                    <li class="textarea up star">
                        <span class="t">施工结果</span>
                        <textarea v-model="item.PROCESS_RESULT_DETAIL" placeholder="请输入施工结果"
                                  :disabled="item.STATUS==1"></textarea>
                    </li>
                    <li>
                        <span class="t">上传图片</span>
                        <div class="imgadd">
                            <!-- <div>
                                <img src="@/assets/images/icon_close.png" class="close" @click='deleteImg(index)'>
                                <img src="@/assets/images/icon_face.png">
                            </div> -->
                            <button @click="chooseImg()">
                                <img src="@/assets/images/Plus.png" mode="widthFix">
                            </button>
                        </div>
                    </li>
				</ul>
			</div>
			<!--燃气维修-->
			<div class="order_box bg-white" v-if="item.CASETYPE == 18">
				<h5>
					<i class="rwd"></i>
					<span>工单信息</span>
				</h5>
				<ul>
					<li>
						<span class="t">工单名称</span>
                        <span class="c">{{item.CASENAME}}</span>
					</li>
					<li>
						<span class="t">工单单号</span>
						<span class="c">{{item.CASEID}}</span>
					</li>
					<li>
						<span class="t">业务小类</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.BUSS_SUBCLASS" :disabled="item.STATUS==1 || true">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in classList18" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li>
						<span class="t">派单人姓名</span>
						<span class="c">{{item.GHID_DIS_NAME}}</span>
					</li>
					<li>
						<span class="t">派单日期</span>
						<span class="c">{{item.DISPATCH_DAT}}</span>
					</li>
					<li>
						<span class="t">紧急程度</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.LEVEL" :disabled="item.STATUS==1 || true">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in levelList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
                    <li class="daka">
                        <span class="t">打卡地址</span>
                        <span class="c">{{item.PUNCH_ADDR == null || item.PUNCH_ADDR == '' ? address : item.PUNCH_ADDR}}</span>
                        <!--<span class="c">{{item.ADDRESS}}</span>-->
                        <button @click="cardClick" v-if="item.STATUS != 5"><img src="@/assets/images/icon_lct.png">
                        </button>
                    </li>
                    <li class="textarea up">
                        <span class="t">问题描述</span>
                        <textarea v-model="item.PROBLEM_DES" placeholder="请输入问题描述"
                                  :disabled="item.STATUS==1 || true"></textarea>
                    </li>
					<li>
						<span class="t">无法施工类型</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.UNBUILD_TYPE" :disabled="item.STATUS==1">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in sgTypeList18" v-bind:key="it" :value="it">{{it}}
								</option>
							</select>
						</span>
					</li>
                    <li class="textarea star up">
                        <span class="t">处理结果</span>
                        <textarea v-model="item.PROCESS_RESULT" id="" placeholder="请输入处理结果"
                                  :disabled="item.STATUS==1"></textarea>
                    </li>
                    <li>
                        <span class="t">上传图片</span>
                        <div class="imgadd">
                            <!-- <div>
                                <img src="@/assets/images/icon_close.png" class="close" @click='deleteImg(index)'>
                                <img src="@/assets/images/icon_face.png">
                            </div> -->
                            <button @click="chooseImg()">
                                <img src="@/assets/images/Plus.png" mode="widthFix">
                            </button>
                        </div>
                    </li>
				</ul>
			</div>
			<!--燃气入户安检工单-->
			<div class="order_box bg-white" v-if="item.CASETYPE == 19">
				<h5>
					<i class="rwd"></i>
					<span>工单信息</span>
				</h5>
				<ul>
					<li>
						<span class="t">工单名称</span>
                        <span class="c">{{item.CASENAME}}</span>
					</li>
					<li>
						<span class="t">工单单号</span>
						<span class="c">{{item.CASEID}}</span>
					</li>
					<li>
						<span class="t">派单人姓名</span>
						<span class="c">{{item.GHID_DIS_NAME}}</span>
					</li>
					<li>
						<span class="t">派单日期</span>
						<span class="c">{{item.DISPATCH_DAT}}</span>
					</li>
					<li class="yhz_btn" v-if="item.STATUS == 2">
						<span class="t">受检用户组</span>
						<span class="c">{{item.INSPECTED_GROUPS}}</span>
						<button @click="openUserGroup(item.CASEID,item.CASETYPE,item.INSPECTED_GROUPS)">用户信息</button>
					</li>	
					<!-- <li>
						<span class="t">紧急程度</span>
						<span class="c">
							<select v-model="item.LEVEL" :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in levelList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
                    <li class="textarea up">
                        <span class="t">问题描述</span>
                        <textarea v-model="item.PROBLEM_DES" placeholder="请输入问题描述"
                                  :disabled="item.STATUS==5"></textarea>
                    </li> -->
					<li class="star">
						<span class="t">处理结果</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.PROCESS_RESULT" :disabled="item.STATUS==1">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in resultList" v-bind:key="it" :value="it">{{it}}
								</option>
							</select>
						</span>
					</li>	
                    <li v-if="item.STATUS == 2 || item.STATUS == 3">
                        <span class="t">上传图片</span>
                        <div class="imgadd">
                            <!-- <div>
                                <img src="@/assets/images/icon_close.png" class="close" @click='deleteImg(index)'>
                                <img src="@/assets/images/icon_face.png">
                            </div> -->
                            <button @click="chooseImg()">
                                <img src="@/assets/images/Plus.png" mode="widthFix">
                            </button>
                        </div>
                    </li>			
				</ul>
			</div>
			<div class="order_box bg-white" v-if="item.CASETYPE == 20">
				<h5>
					<i class="rwd"></i>
					<span>工单信息</span>
				</h5>
				<ul>
					<li>
						<span class="t">工单名称</span>
                        <span class="c">{{item.CASENAME}}</span>
					</li>
					<li>
						<span class="t">工单单号</span>
						<span class="c">{{item.CASEID}}</span>
					</li>
					<li>
						<span class="t">业务小类</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="item.BUSS_SUBCLASS" :disabled="item.STATUS==5 || true">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in classList20" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li>
						<span class="t">派单人姓名</span>
						<span class="c">{{item.GHID_DIS_NAME}}</span>
					</li>
					<li>
						<span class="t">派单日期</span>
						<span class="c">{{item.DISPATCH_DAT}}</span>
					</li>
                    <li class="textarea up">
                        <span class="t">问题描述</span>
                        <textarea v-model="item.PROBLEM_DES" placeholder="请输入问题描述"
                                  :disabled="item.STATUS==5 || true"></textarea>
                    </li>
                    <li class="textarea star up">
                        <span class="t">处理结果</span>
                        <textarea v-model="item.PROCESS_RESULT" id="" placeholder="请输入处理结果"
                                  :disabled="item.STATUS==5"></textarea>
                    </li>	
				</ul>
			</div>
			
		</div>
		<!-- footer -->
		<div class="footer_btn" v-if="item.CASETYPE == 17"><!--燃气预约-->
			<button class="jiedan" @click="jieDan" v-if="item.STATUS == 1 || item.STATUS == 3">接单</button>
            <button class="zhuanpai" @click="changePeople" v-if="item.STATUS == 2 || item.STATUS == 4">转派</button>
			<button class="wandan" @click="endDan" v-if="item.STATUS == 2 || item.STATUS == 4">完单</button>
			<button class="save" @click="saveDan" :disabled="btnDisabled" v-if="item.STATUS == 2 || item.STATUS == 4">保存</button>
			<button class="tuidan" @click="guaqiDan" v-if="item.STATUS == 2">挂起</button>
			<button class="back" @click="back">返回</button>
		</div>
		<div class="footer_btn" v-if="item.CASETYPE == 18"><!--燃气维修-->
			<button class="jiedan" @click="jieDan" v-if="item.STATUS == 1 || item.STATUS == 3">接单</button>
			<button class="save" @click="saveDan" :disabled="btnDisabled" v-if="item.STATUS == 2 || item.STATUS == 4">保存</button>
			<button class="tuidan" @click="guaqiDan" v-if="item.STATUS == 2">挂起</button>
			<button class="wandan" @click="endDan" v-if="item.STATUS == 2 || item.STATUS == 4">完单</button>
            <button class="zhuanpai" @click="changePeople"  v-if="item.STATUS == 2 || item.STATUS == 4">转派</button>
			<button class="back" @click="back">返回</button>
		</div>
		<div class="footer_btn" v-if="item.CASETYPE == 19"><!--燃气入户安检工单-->
			<button class="jiedan" @click="jieDan" v-if="item.STATUS == 1">接单</button>
			<!--<button class="save" @click="saveDan" :disabled="btnDisabled">保存</button>
			<button class="tuidan" @click="guaqiDan" v-if="item.STATUS == 2">挂起</button>-->
			<button class="wandan" @click="endDan" v-if="item.STATUS == 2">完单</button>
			<button class="back" @click="back">返回</button>
		</div>
		<div class="footer_btn" v-if="item.CASETYPE == 20"><!--燃气业务变更-->
			<button class="jiedan" @click="jieDan" v-if="item.STATUS == 1">接单</button>
			<button class="save" @click="saveDan" v-if="item.STATUS == 2">保存</button>
			<button class="wandan" @click="endDan" v-if="item.STATUS == 2">完单</button>
			<button class="back" @click="back">返回</button>
		</div>
		<div class="mask-box" v-if="showPop">
			<div class="order_layer">
				<div class="sel">
					<span>组织<span class="red">*</span></span>
					<select v-model="orgItem" @change="selectOrg">
						<option style="display:none" value="" disabled>请选择</option>
						<option v-for="it in orgList" v-bind:key="it.org_code" :value="it">{{it.org_name}}</option>
					</select>
				</div>
				<div class="sel">
					<span>人员<span class="red">*</span></span>
					<select v-model="rel_ghid" @change="selectMem">
						<option style="display:none" value="" disabled>请选择</option>
						<option v-for="it1 in memList" v-bind:key="it1.rel_ghid" :value="it1.rel_ghid">
							{{it1.rel_name}}
						</option>
					</select>
				</div>
				<div class="btns">
					<button class="cancel" @click="hidePop">取消</button>s
					<button class="seccess" @click="submitPop">确定</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CommonHeader from '../components/CommonHeader.vue'
	import {
		getValue
	} from '@/utils/auth'
	import {
		getDetail,
		getOrgMember,
		getGroupMember,
		cardSign,
		updateDetailGas,
		updateImg
	} from "@/api/order";
    import wx from 'weixin-js-sdk';
    import {getConfig, getAddress} from "@/api/login";

	export default {
		name: 'orderDetail',
		components: {
			CommonHeader
		},
		data() {
			return {
				ghid: getValue('ghid'),
				approveGhid: '8600,8630,8889,8888',
				keyword: '',
				caseid: '',
				casetype: '1',
				token: getValue('token'),
				process_result: '',
				fromHome: 0,
				preStatus: 1,
				job: 401,
				statusdes: '',
                lat: '',
                lng: '',
                address: '',
				item: {},
				showPop: false,
				GROUP_DEP: '',
				TROUBLE_TYPE: '',
				TROUBLE_SOURCE: '',
				IF_COMPANY_DUTY: '',
				IF_FINISH: '',
				IF_TRUE: '',
				levelList: [{
					id: 1,
					val: '正常'
				}, {
					id: 2,
					val: '紧急'
				}, {
					id: 3,
					val: '十分紧急'
				}],
				hegeList: ['合格','不合格'],
				resultList: ['挂起','未处理','已处理'],
				classList18: [{
						id: 66,
						val: '余额不上表'
					}, {
						id: 67,
						val: '无法充值'
					},
					{
						id: 68,
						val: '漏气'
					}, {
						id: 69,
						val: '表不过气'
					},
					{
						id: 70,
						val: '气不上表'
					}, {
						id: 71,
						val: '气量用的快'
					}, {
						id: 72,
						val: '电池用的快'
					}, {
						id: 73,
						val: '气堵'
					}, {
						id: 74,
						val: '立管整改'
					}, {
						id: 75,
						val: '市政管网漏气'
					}, {
						id: 76,
						val: '庭院管网漏气'
					}, {
						id: 77,
						val: '其他'
					}
				],				
				classList20: [{
						id: 78,
						val: '更名'
					}, {
						id: 79,
						val: '过户'
					},
					{
						id: 80,
						val: '地址变更'
					}, {
						id: 81,
						val: '表号错误'
					},
					{
						id: 82,
						val: '电话变更'
					}, {
						id: 83,
						val: '其他'
					}
				],
				sgTypeList17: ['燃气灶安全距离不够', '热水器安全距离不够', '管道封堵需要整改', '燃气表密封需要整改', '操作空间不够,无法施工', '管卡缺失或未固定', '私改燃气管道需要整改', '自装用户检验报告、合格证及从业证书等不全', '自装用户有燃气泄露部位', '其他(在备注手写不能通气原因)'
				],
				sgTypeList18: ['表前漏气', '卡表充值不上表', '通信异常', '备用维修使用表具不齐全', '电池耗电快', '用户灶具、热水器故障','用户自装(管件不匹配）', '表具异常故障需换表', '立管三通堵塞，过气小','缺少管卡，无膨胀螺栓','其他(在备注手写不能维修原因)'
				],
				deptName: '',
				tsType: '',
				typeList: [],
				orgList: [],
				btnDisabled: false,
				orgItem: {},
				memList: [],
				gmemList: [],
				rel_ghid: 0,
				approveRemark: '',
			}
		},
		mounted() {
			var that = this;
			that.caseid = that.$router.history.current.query.CASEID;
			that.casetype = that.$router.history.current.query.CASETYPE;
			that.preStatus = that.$router.history.current.query.status;
			that.fromHome = that.$router.history.current.query.fromHome;
			that.keyword = that.$router.history.current.query.keyword;
			console.log('keyword====' + that.keyword);
            if(that.casetype == 17 || that.casetype == 18) {
				that.getLocation();
			}
			that.getOrgMember();
			that.getDetail();
		},
		methods: {
			//获取工单详情
			getDetail() {
				var data = {
					ghid: this.ghid,
					caseid: this.caseid,
					casetype: this.casetype,
					token: this.token
				}
				console.log("开始获取工单详情===" + this.caseid)
				getDetail(data).then(res => {
					if (res.code == 1) {
						this.item = res.data;
						if(this.casetype == 19) {
							this.getGroupMember(res.data.INSPECTED_GROUPS);
						}
						var STATUS = res.data.STATUS;
						var CASETYPE = res.data.CASETYPE
						var ctName = '电力抢险抢修工单'
						if (CASETYPE == 2) {
							ctName = '电力复电工单'
						} else if (CASETYPE == 4) {
							ctName = '电力业务变更工单'
						} else if (CASETYPE == 5) {
							ctName = '电力非抢修工单'
						} else if (CASETYPE == 7) {
							ctName = '热力抢险抢修工单'
						} else if (CASETYPE == 6) {
							ctName = '热力预约工单'
						} else if (CASETYPE == 8) {
							ctName = '热力营销工单'
						} else if (CASETYPE == 17) {
							ctName = '燃气预约工单'
						} else if (CASETYPE == 18) {
							ctName = '燃气维修工单'
						} else if (CASETYPE == 19) {
							ctName = '燃气入户安检工单'
						} else if (CASETYPE == 20) {
							ctName = '燃气业务变更工单'
						}							
						if(CASETYPE == 19 && (this.item.INSPECTED_USERS == null || this.item.INSPECTED_USERS == '')){
							this.saveUserGroup(this.item.INSPECTED_GROUPS);
						}
						// this.saveUserGroup(this.item.INSPECTED_GROUPS);
						this.item.ctName = ctName;
						this.process_result = res.data.PROCESS_RESULT;
						this.TROUBLE_SOURCE = res.data.TROUBLE_SOURCE;
						this.TROUBLE_TYPE = res.data.TROUBLE_TYPE;
						if (STATUS == 0) {
							this.statusdes = '未处理';
						} else if (STATUS == 1) {
							this.statusdes = '已派单';
							if(CASETYPE == 20) {
								this.statusdes = '已分配';
							}
						} else if (STATUS == 2) {
							this.statusdes = '处理中';
							if(CASETYPE == 18) {
								this.statusdes = '已接单';
							}
						} else if (STATUS == 3) {
							this.statusdes = '转派';
							if(CASETYPE == 19) {
								this.statusdes = '挂起';
							}
						} else if (STATUS == 4) {
							this.statusdes = '挂起';
							if(CASETYPE == 19) {
								this.statusdes = '完结';
							}
							if(CASETYPE == 20) {
								this.statusdes = '退单';
							}
						} else if (STATUS == 5) {
							this.statusdes = '完结';
						} else if (STATUS == 100) {
							this.statusdes = '闭单';
						}
						
					}
				});
			},
            //点击打卡
            cardClick() {
                var that = this;
                console.log('that.address==' + that.address);
                console.log('that.lat==' + that.lat);
                console.log('that.lng==' + that.lng);
                if (that.address == '') {
                    that.$toast({
                        text: '请获取地点！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                that.$confirm('确定要打卡吗？', () => {
                    console.log('这是确定事件');
                    var data = {
                        ghid: that.ghid,
                        token: that.token,
                        caseid: that.caseid,
                        casetype: that.casetype,
                        lat: that.lat,
                        lng: that.lng,
                        address: that.address
                    }
                    cardSign(data).then(res => {
                        if (res.code == 1 && res.state != 'FAILURE') {
                            that.$toast({
                                text: '打卡成功！',
                                type: 'success',
                                duration: 1500
                            })
                            that.getDetail();
                        } else {
                            that.$toast({
                                text: res.MSG,
                                type: 'wraning',
                                duration: 1500
                            })
                        }

                    });
                }, () => {
                    console.log('这是取消事件');
                })
            },//获取当前位置
            getLocation() {
                    console.log('getLocation======');
                var that = this;
                var data = {
                    url: location.href.split('#')[0]
                }
                getConfig(data).then(res => {
                    console.log('getLocation=222=====');
                    if (res.code == 1) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.appId, // 必填，公众号的唯一标识
                            timestamp: res.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.nonceStr, // 必填，生成签名的随机串
                            signature: res.signature, // 必填，签名
                            jsApiList: ['getLocation','chooseImage'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
                        });
                        wx.ready(function () {
                            wx.getLocation({
                                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                                success: function (res) {
                                    console.log('res====111========' + JSON.stringify(res));
//                                    that.process_result = JSON.stringify(res);
                                    that.lat = Math.round(res.latitude * 1000000) / 1000000;
                                    that.lng = Math.round(res.longitude * 1000000) / 1000000;
                                    getAddress({latitude: that.lat, longitude: that.lng}).then(r => {
                                        console.log(r.address);
                                        that.address = r.address;
                                    });
                                },
                                fail: function (res) {
                                    console.log('res=====22=======' + JSON.stringify(res));
                                }
                            });
                        });
                    }
                });
            },
			//打开用户组页面
			openUserGroup(CASEID, CASETYPE,INSPECTED_GROUPS) {
                var that = this;
				that.$router.push({
					name: 'user_group',
					query: {
						CASEID: CASEID,
						CASETYPE: CASETYPE,
						INSPECTED_GROUPS: INSPECTED_GROUPS,
					}
				});
            },
			//获取安全组人员
			getGroupMember(groups) {
				var data = {
					ghid: this.ghid,
					groups: groups,
					token: this.token
				}
				getGroupMember(data).then(res => {
					if (res.code == 1) {
						this.gmemList = res.data.member;
					}
				});
			},
			
			//获取组织结构
			getOrgMember() {
				var data = {
					ghid: this.ghid,
					token: this.token
				}
				getOrgMember(data).then(res => {
					if (res.code == 1) {
						this.orgList = res.data
						for (var i = 0; i < this.orgList.length; i++) {
							var member = this.orgList[i].member;
							var flag = false;
							for (var j = 0; j < member.length; j++) {
								if (this.ghid == member[j].rel_ghid) {
									this.job = member[j].job;
									flag = true;
									break;
								}
							}
							if (flag) {
								break;
							}
						}
					}
				});
			},
			
			//上传照片
			chooseImg() {
				var that = this;
				var data = {
					url: location.href.split('#')[0]
				}
				console.log('chooseImg==');
				getConfig(data).then(res => {
					if (res.code == 1) {
						wx.config({
							debug: false,
							appId: res.appId, // 必填，公众号的唯一标识
							timestamp: res.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.nonceStr, // 必填，生成签名的随机串
							signature: res.signature, // 必填，签名
							jsApiList: ['chooseImage'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
						});
						wx.ready(function () {
							wx.chooseImage({
								count: 1, // 默认9
								sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
								sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
								success: function (res) {
									console.log('res=1111=='+JSON.stringify(res));
									var localIds  = res.localIds;
									console.log("开始获取工11单详情==="+localIds[0]);
									var imgData = {
										ghid: that.ghid,
										caseid: that.caseid,
										casetype: that.casetype,
										casename: that.item.CASENAME,
										token: that.token,
									}
									wx.getLocalImgData({
										localId: localIds[0].toString(),
										success: function (res) {
											const localData = "data:image/jpeg;base64,"+res.localData;

											imgData.base64Img = [localData]; 
											updateImg(imgData).then(r => {
												
												if(r.state == 'SUCCESS') {													
													that.$toast({
														text: '上传成功！',
														type: 'success',
														duration: 2000
													})
												}
											});
										},
									});
									
								},
								fail(res) {
									
									that.$toast({
										text: JSON.stringify(res),
										type: 'wraning',
										duration: 1500,
									});
								}
							});
						});
					}
				});
			},
			//接单
			jieDan() {
				this.$confirm('确定要接单吗？', () => {
					var data = {
						ghid: this.ghid,
						token: this.token,
						caseid: this.caseid,
						casetype: this.casetype,
						status: 0
					}
					updateDetailGas(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							this.$toast({
								text: '接单成功！',
								type: 'success',
								duration: 1500
							})
							this.getDetail();
						} else {
							this.$toast({
								text: res.msg,
								type: 'wraning',
								duration: 1500
							})
						}
					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			tel: function(mobile) {
				console.log(mobile);
				window.location.href = 'tel://' + mobile;
			},
			onCopy: function() {
				var that = this;
				that.$toast({
					text: '复制成功！',
					type: 'success',
					duration: 1500
				})
			},
			onError: function() {
				var that = this;
				that.$toast({
					text: '复制失败！',
					type: 'wraning',
					duration: 1500
				})
			},
			//保存
			saveDan() {
				var that = this;			
				var data = {
					ghid: this.ghid,
					token: this.token,
					caseid: this.caseid,
					casetype: this.casetype,
					status: 4,
				};
				var changedata = {};
				if(that.casetype == 17) {
					if(that.item.IS_QUALIFIED == '不合格') {
						if (that.item.UN_QUALIFIED_REASON == '' || that.item.UN_QUALIFIED_REASON == null) {
							that.$toast({
								text: '请输入不合格原因！',
								type: 'wraning',
								duration: 1500
							});
							return false;
						}
					}
					// changedata.LEVEL= that.item.LEVEL;
					// changedata.PROBLEM_DES= that.item.PROBLEM_DES;
					changedata.IS_QUALIFIED= that.item.IS_QUALIFIED == null ? '' : that.item.IS_QUALIFIED;
					changedata.UN_QUALIFIED_REASON= that.item.UN_QUALIFIED_REASON == null ? '' : that.item.UN_QUALIFIED_REASON;
					changedata.UNBUILD_TYPE= that.item.UNBUILD_TYPE == null ? '' : that.item.UNBUILD_TYPE;
					if (that.item.PROCESS_RESULT == '' || that.item.PROCESS_RESULT == null) {
						that.$toast({
							text: '请输入处理结果！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.item.PROCESS_RESULT_DETAIL == '' || that.item.PROCESS_RESULT_DETAIL == null) {
						that.$toast({
							text: '请输入施工结果！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					changedata.PROCESS_RESULT= that.item.PROCESS_RESULT;
					changedata.PROCESS_RESULT_DETAIL= that.item.PROCESS_RESULT_DETAIL;
				}
				if(that.casetype == 18) {
					// changedata.BUSS_SUBCLASS= that.item.BUSS_SUBCLASS;
					// changedata.LEVEL= that.item.LEVEL;
					// changedata.PROBLEM_DES= that.item.PROBLEM_DES;
					changedata.UNBUILD_TYPE= that.item.UNBUILD_TYPE == null ? '' : that.item.UNBUILD_TYPE;
					if (that.item.PROCESS_RESULT == '' || that.item.PROCESS_RESULT == null) {
						that.$toast({
							text: '请输入处理结果！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					changedata.PROCESS_RESULT= that.item.PROCESS_RESULT;
				}
				if(that.casetype == 19) {
					// changedata.LEVEL= that.item.LEVEL;
					// changedata.PROBLEM_DES= that.item.PROBLEM_DES;
					if (that.item.PROCESS_RESULT == '' || that.item.PROCESS_RESULT == null) {
						that.$toast({
							text: '请输入处理结果！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					changedata.PROCESS_RESULT= that.item.PROCESS_RESULT;
				}
				if(that.casetype == 20) {
					// changedata.BUSS_SUBCLASS= that.item.BUSS_SUBCLASS;
					// changedata.PROBLEM_DES= that.item.PROBLEM_DES;				
					if (that.item.PROCESS_RESULT == '' || that.item.PROCESS_RESULT == null) {
						that.$toast({
							text: '请输入处理结果！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					changedata.PROCESS_RESULT= that.item.PROCESS_RESULT;
				}
				data.changedata = [changedata];
				that.btnDisabled = true;
				updateDetailGas(data).then(res => {
					if (res.code == 1 && res.state != 'FAILURE') {
						this.$toast({
							text: '保存成功！',
							type: 'success',
							duration: 1500
						})
					} else {
						this.$toast({
							text: res.msg,
							type: 'wraning',
							duration: 1500
						})
					}
					setTimeout(function() {
						that.btnDisabled = false;
					}, 2500);
				});
			},

			//退单ss
			guaqiDan() {
				this.$confirm('确定要挂起吗？', () => {
					var data = {
						ghid: this.ghid,
						token: this.token,
						caseid: this.caseid,
						casetype: this.casetype,
						status: 5
					}
					var changedata = {};
					changedata.HANG_UP_DATE = new Date();
					data.changedata = [changedata];
					updateDetailGas(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							this.$toast({
								text: '挂起成功！',
								type: 'success',
								duration: 1500
							});
							this.getDetail();
						} else {
							this.$toast({
								text: res.msg,
								type: 'wraning',
								duration: 1500
							})
						}
					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			//转单
			changePeople() {
				this.getOrgMember();
				this.showPop = true;
			},
			//完单
			endDan() {
				var that = this;
				that.getDetail();
				console.log('process_result=' + that.process_result);
				var data = {
					ghid: this.ghid,
					token: this.token,
					caseid: this.caseid,
					casetype: this.casetype,
					status: 2
				}
				var changedata = {};
				if (that.item.PROCESS_RESULT == '' || that.item.PROCESS_RESULT == null) {
					that.$toast({
						text: '请输入处理结果！',
						type: 'wraning',
						duration: 1500
					});
					return false;
				}
				changedata.PROCESS_RESULT = that.item.PROCESS_RESULT == null ? '' : that.item.PROCESS_RESULT;
				// changedata.HANG_UP_DATE = new Date().getTime();
				if(that.casetype == 19) {						
					if(that.item.INSPECTED_USERS.indexOf('未处理')>-1 || that.item.INSPECTED_USERS.indexOf('挂起中')>-1){
						this.$toast({
							text: '有未完成的用户组！不能提交！',
							type: 'wraning',
							duration: 1500
						})
						return false;
					}
				}
				changedata.FINISH_TIME = new Date().getTime();
				data.changedata = [changedata];
				this.$confirm('确定要完单吗？', () => {
					updateDetailGas(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							this.$toast({
								text: '完单成功！',
								type: 'success',
								duration: 1500
							});
							this.getDetail();
						} else {
							this.$toast({
								text: res.msg,
								type: 'wraning',
								duration: 1500
							})
						}
					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			//初始化保存用户组
			saveUserGroup(groups) {
				var data = {
					ghid: this.ghid,
					groups: groups,
					token: this.token
				}
				getGroupMember(data).then(res => {
					if (res.code == 1) {
						var list = res.data[0].member;
						var str = '{';							
						for(var i=0;i<list.length;i++) {
							if(i == (list.length-1)) {
								str = str + '姓名:'+list[i].UNAME+';手机号:'+list[i].PHONE+';地址:'+list[i].ADDRESS+';状态:未处理;';
							}else {
								str = str + '姓名:'+list[i].UNAME+';手机号:'+list[i].PHONE+';地址:'+list[i].ADDRESS+';状态:未处理;\n';
							}
						}
						str = str + '}';
						console.log('str==='+str);
						var tdata = {
							ghid: this.ghid,
							token: this.token,
							caseid: this.caseid,
							casetype: this.casetype,
							transfer_ghid: "",
							changedata: [{INSPECTED_USERS:str}],
							status: 4,
						};
						//data.INSPECTED_USERS = '[{姓名:张三;手机号:13167190694;地址:郑州市;状态:已完成;}]';
						updateDetailGas(tdata).then(res1 => {
							if (res1.code == 1 && res1.state != 'FAILURE') {
								console.log('保存成功！');
							} else {
								this.$toast({
									text: res1.msg,
									type: 'wraning',
									duration: 1500
								})
							}
						});
					}
				});
			},
			//选择对应的故障源
			selectSource(idx) {
				
				console.log('idx======' + idx);
			},
			//选择对应的组织
			selectOrg() {
				this.memList = this.orgItem.member;
			},
			//选择对应的人员
			selectMem() {
				console.log('this.rel_ghid' + this.rel_ghid);
			},
			//取消时隐藏弹窗
			hidePop() {
				this.showPop = false;
			},
			//提交弹出页
			submitPop() {
				var data = {
					ghid: this.ghid,
					token: this.token,
					caseid: this.caseid,
					casetype: this.casetype,
					status: 3,					
					changedata: [{transfer_ghid:this.rel_ghid}],
				}				
				updateDetailGas(data).then(res => {
					if (res.code == 1 && res.state != 'FAILURE') {
						this.showPop = false;
						this.$toast({
							text: '转派成功！',
							type: 'success',
							duration: 1500
						});
						this.getDetail();
					} else {
						this.showPop = false;
						this.$toast({
							text: res.msg,
							type: 'wraning',
							duration: 2500
						})
					}
				});
			},
			//返回上一页
			back() {
				var that = this;
				if (that.fromHome == 1) {
					that.$router.push({
						name: 'home'
					})
				} else {
					that.$router.push({
						name: 'order',
						query: {
							status: that.preStatus,
							keyword: that.keyword
						}
					})
				}
				//                this.$router.go(-1);//返回上一层
			},
		},
		filters: {
			formatDate: function(value) {
				return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
			},

			formatTime: function(value) {
				return value.substring(0, 2) + ':' + value.substring(2, 4) + ':' + value.substring(4, 6)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
	@import "../assets/css/bootstrap.min.css";
	@import "../assets/css/style.css";
	@import "../assets/css/swiper-bundle.min.css";

	.imgadd {
		flex: 1;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		margin-left: 10px;
	}

	.imgadd>div {
		position: relative;
	}

	.imgadd img {
		width: 100%;
		height: auto;
		position: relative;
		z-index: 10;
		min-height: 60px;
	}

	.imgadd img.close {
		width: 20px;
		height: 20px !important;
		min-height: 20px !important;
		position: absolute;
		top: -5px;
		right: -5px;
		background: #f00;
		border-radius: 100px;
		z-index: 20;
		opacity: 1;
	}

	.imgadd button {
		border: 1px #eee solid;
		border-radius: 4px;
		background: #FAFAFA;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.imgadd button img {
		width: 30px;
		height: 30px !important;
		min-height: 30px !important;
	}
	
	.yhz_btn{display: flex;align-items: center;}
	.yhz_btn button{margin-left: auto;background: #4CB4B3;border-radius: 4px; height: 32px;padding: 0 10px;color: #fff;font-size: 12px;border: 0;}
	
</style>
