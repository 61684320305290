<template>
  <div id="app">
    <router-view meta-title/>
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
//import HelloWorld from './components/CommonHeader.vue'

export default {
  name: 'App',
  components: {
//    HelloWorld
  }
}
</script>

<style>
  body {
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
	
	
  }

  body {
    margin: 0px;
    padding: 0px;
    border: 0;
    font-size: 14px;
    font-family: -apple-system,"Helvetica Neue","Helvetica","Arial","PingFang SC","Hiragino Sans GB","STHeiti","Microsoft YaHei","Microsoft JhengHei",SimSun,sans-serif;
    background: #F1F1F1;
    color: #333;
  }
</style>
