<!-- header -->
<template>
	<div id="big_box">
		<CommonHeader title="工单详情" isBack="1" />
		<!-- 中间内容区域  高度自适应 -->
		<div id="sention">
			<!-- 联系方式 -->
			<div class="order_box bg-white">
				<h5>
					<i class="fwnr"></i>
					<span>客户信息</span>
				</h5>
				<ul>
					<li style="display: none;">
						<span class="t">客户编号</span>
						<span class="c">{{item.USERID}}</span>
					</li>
					<li>
						<span class="t">客户名称</span>
						<span class="c">{{item.UNAME}}</span>
					</li>
					<li class="tel">
						<span class="t">主叫号码</span>
						<span class="c" @click="tel(item.CALLER)">{{item.CALLER}}</span>
						<button v-clipboard:copy="item.CALLER" v-clipboard:success="onCopy" v-clipboard:error="onError">
							<img src="@/assets/images/icon_copy.png">
						</button>
					</li>
					<li>
						<span class="t">来电日期</span>
						<span class="c">{{item.SDATE | formatDate}} {{item.STIME | formatTime}}</span>
					</li>
				</ul>
			</div>
			<!-- 服务内容 -->
			<!-- 任务单详情 -->
			<div class="order_box bg-white">
				<h5>
					<i class="rwd"></i>
					<span>工单信息</span>
				</h5>
				<ul>
					<li>
						<span class="t">工单编号</span>
						<span class="c">{{item.CASEID}}</span>
					</li>
					<li>
						<span class="t">工单名称</span>
						<span class="c">{{item.CASENAME}}</span>
					</li>
					<li>
						<span class="t">派单人姓名</span>
						<span class="c">{{item.GHID_DIS_NAME}}</span>
					</li>
					<li>
						<span class="t">投诉部门</span>
						<span class="c">{{deptName}}</span>
					</li>
					<li>
						<span class="t">投诉类型</span>
						<span class="c">{{tsType}}</span>
					</li>
					<li class="textarea up">
						<span class="t">投诉信息描述</span>
						<textarea v-model="item.PROBLEM_DES" readonly></textarea>
					</li>
					<li class="textarea up" v-if="job == 402" style="display: none;">
						<span class="t">审核建单意见</span>
						<textarea v-model="item.CHECK_USER_VIEW"></textarea>
					</li>
					<li class="textarea up">
						<span class="t">审核意见</span>
						<textarea v-model="check_receive_view" v-bind:disabled="viewisDisabled"></textarea>
					</li>
					<li class="star" v-if="job != 402 && item.STATUS != 1">
						<span class="t">是否公司责任</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="IF_COMPANY_DUTY" @change="selectSource(2)" :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in yesList1" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li class="daka" v-if="job != 402 && item.STATUS != 1">
						<span class="t">接单打卡</span>
						<span
							class="c">{{item.PUNCH_ADDR == null || item.PUNCH_ADDR == '' ? address : item.PUNCH_ADDR}}</span>
						<!--<span class="c">{{item.ADDRESS}}</span>-->
						<button @click="cardClick" v-if="item.STATUS != 5"><img src="@/assets/images/icon_lct.png">
						</button>
					</li>
					<li v-if="job != 402 && item.STATUS != 1">
						<span class="t">首次联系用户打卡时间</span>
						<span class="c">{{item.FIRST_PUNCH_TIME}}</span>
						<button style="background: #00A991;height: 30px;color: white;padding: 2px;border: 0;" @click="getNowTime()">
							当前时间
						</button>
					</li>
					<li class="star" v-if="job != 402 && item.STATUS != 1">
						<span class="t">是否办结</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="IF_FINISH" @change="selectSource(3)" :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in yesList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li class="star" v-if="job != 402 && item.STATUS != 1">
						<span class="t">是否已答复用户</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="IF_ANSWER_USER"  @change="selectSource(5)"  :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in yesList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li class="star" v-if="job != 402 && item.STATUS != 1">
						<span class="t">用户是否满意</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="IF_USER_SATISFIED"  @change="selectSource(5)"  :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in yesList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>					
					<li class="textarea star up" v-if="job != 402 && item.STATUS != 1">
						<span class="t">用户意见</span>
						<textarea v-model="item.USER_VIEW" id="" placeholder="请输入用户意见"
							:disabled="item.STATUS==5"></textarea>
					</li>
					<li class="star" v-if="job != 402 && item.STATUS != 1">
						<span class="t">处理部门</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="GROUP_DEP" :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in deptList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li class="star" v-if="job != 402 && item.STATUS != 1">
						<span class="t">是否属实</span>
						<span class="c">
							<!--{{item.PROBLEM_DES}}-->
							<select v-model="IF_TRUE" @change="selectSource(1)" :disabled="item.STATUS==5">
								<option style="display:none" value="" disabled>请选择</option>
								<option v-for="it in yesList" v-bind:key="it.id" :value="it.id">{{it.val}}
								</option>
							</select>
						</span>
					</li>
					<li class="textarea star up" v-if="job != 402 && item.STATUS != 1">
						<span class="t">处理结果</span>
						<textarea v-model="process_result" id="" placeholder="请输入处理结果"
							:disabled="item.STATUS==5"></textarea>
					</li>
                    <li v-if="item.STATUS == 2 || item.STATUS == 3">
                        <span class="t">上传图片</span>
                        <div class="imgadd">
                            <!-- <div>
                                <img src="@/assets/images/icon_close.png" class="close" @click='deleteImg(index)'>
                                <img src="@/assets/images/icon_face.png">
                            </div> -->
                            <button @click="chooseImg()">
                                <img src="@/assets/images/Plus.png" mode="widthFix">
                            </button>
                        </div>
                    </li>
				</ul>
			</div>
		</div>
		<!-- footer -->
		<div class="footer_btn">
            
			<button class="jiedan" @click="jieDan" v-if="item.STATUS == 1">
				接单
			</button>
			<button class="zhuanpai" @click="saveDan"
				:disabled="btnDisabled" v-if="(item.STATUS == 1 && job == 402) || (item.STATUS == 2 && job == 401)">
				保存
			</button>
			<button class="wandan" @click="endDan" v-if="item.STATUS == 2 && job == 401">完单</button>

			<button class="back" @click="back">返回</button>
		</div>

        <!-- footer 修改前-->
        <!-- <div class="footer_btn">
            
			<button class="jiedan" @click="jieDan" v-if="item.STATUS == 1">
				接单
			</button>
			<button class="zhuanpai" @click="saveDan"
				:disabled="btnDisabled" v-if="(item.STATUS == 1 && job == 402) || (item.STATUS == 2 && job == 401)">
				保存
			</button>
			<button class="wandan" @click="endDan" v-if="item.STATUS == 2 && job == 401">完单</button>
			<button class="jiedan" @click="approve(6)" v-if="item.STATUS == 1 && job == 402">
				审核通过
			</button>
			<button class="zhuanpai" @click="approve(7)" v-if="item.STATUS == 1 && job == 402">
				审核不通过
			</button>
			<button class="back" @click="back">返回</button>
		</div> -->


	</div>
</template>

<script>
	import CommonHeader from '../components/CommonHeader.vue'
	import {
		getValue
	} from '@/utils/auth'
	import {
		getDetail,
		getOrgMember,
		cardSign,
		updateDetail,
		updateDetailGas,
		updateImg
	} from "@/api/order";
	import wx from 'weixin-js-sdk';
	import {
		getConfig,
		getAddress
	} from "@/api/login";

	export default {
		name: 'orderDetail',
		components: {
			CommonHeader
		},
		data() {
			return {
				ghid: getValue('ghid'),
				approveGhid: '8600,8630,8889,8888',
				keyword: '',
				caseid: '',
				casetype: '1',
				token: getValue('token'),
				process_result: '',
				fromHome: 0,
				preStatus: 1,
				job: 401,
				statusdes: '',
				lat: '',
				lng: '',
				address: '',
				item: {},
				showPop: false,
				viewisDisabled:false,
				GROUP_DEP: '',
				TROUBLE_TYPE: '',
				TROUBLE_SOURCE: '',
				IF_COMPANY_DUTY: '',
				IF_FINISH: '',
				IF_ANSWER_USER: '',
				IF_USER_SATISFIED: '',
				IF_TRUE: '',
				yesList: [{
					id: 0,
					val: '否'
				}, {
					id: 1,
					val: '是'
				}],
				yesList1: [{
					id: 45,
					val: '否'
				}, {
					id: 44,
					val: '是'
				}],
				deptList: [{
						id: 101005001001,
						val: '兴港电力'
					}, {
						id: 101005001002,
						val: '兴港燃气'
					},
					{
						id: 101005001003,
						val: '热力市场开发部'
					}, {
						id: 101005001004,
						val: '热力供热运行部'
					},
					{
						id: 101005001005,
						val: '客服中心'
					}, {
						id: 101005001006,
						val: '其他'
					}
				],
				tsTypeList: [{
						id: 31,
						val: '服务超时'
					}, {
						id: 32,
						val: '窗口人员服务行为'
					},
					{
						id: 33,
						val: '一线工程师服务行为'
					}, {
						id: 34,
						val: '服务质量'
					},
					{
						id: 35,
						val: '行风问题'
					}, {
						id: 36,
						val: '未按计划停送电'
					},
					{
						id: 37,
						val: '未按计划停送气'
					}, {
						id: 38,
						val: '未按计划停送暖'
					},
					{
						id: 39,
						val: '无故中断供应'
					}, {
						id: 40,
						val: '业务受理'
					},
					{
						id: 41,
						val: '现场施工规范'
					}, {
						id: 42,
						val: '供能质量'
					},
					{
						id: 43,
						val: '其它投诉'
					}, {
						id: 99,
						val: '石油投诉'
					},
					{
						id: 100,
						val: '石化投诉'
					}, {
						id: 101,
						val: '智慧城市'
					}
				],
				deptName: '',
				tsType: '',
				typeList: [],
				orgList: [],
				btnDisabled: false,
				orgItem: {},
				memList: [],
				rel_ghid: 0,
				approveRemark: '',
			}
		},
		mounted() {
			var that = this;
			that.caseid = that.$router.history.current.query.CASEID;
			that.casetype = that.$router.history.current.query.CASETYPE;
			that.preStatus = that.$router.history.current.query.status;
			that.fromHome = that.$router.history.current.query.fromHome;
			that.keyword = that.$router.history.current.query.keyword;
			console.log('keyword====' + that.keyword);
			that.getLocation();
			that.getOrgMember();
			that.getDetail();
		},
		methods: {
			//获取工单详情
			getDetail() {
				var data = {
					ghid: this.ghid,
					caseid: this.caseid,
					casetype: this.casetype,
					token: this.token
				}
				console.log("开始获取工单详情===" + this.caseid)
				getDetail(data).then(res => {
					if (res.code == 1) {
						this.item = res.data;
						var STATUS = res.data.STATUS;
						this.viewisDisabled = !(STATUS == 1);//控制审核意见是否只读
						
						var CASETYPE = res.data.CASETYPE
						var ctName = '电力抢险抢修工单'
						if (CASETYPE == 2) {
							ctName = '电力复电工单'
						} else if (CASETYPE == 4) {
							ctName = '电力业务变更工单'
						} else if (CASETYPE == 5) {
							ctName = '电力非抢修工单'
						} else if (CASETYPE == 7) {
							ctName = '热力抢险抢修工单'
						} else if (CASETYPE == 6) {
							ctName = '热力预约工单'
						} else if (CASETYPE == 8) {
							ctName = '热力营销工单'
						} else if (CASETYPE == 13) {
							ctName = '投诉工单'
						}
						this.item.ctName = ctName;
						this.check_receive_view = res.data.CHECK_RECEIVE_VIEW;
						this.process_result = res.data.PROCESS_RESULT;
						this.TROUBLE_SOURCE = res.data.TROUBLE_SOURCE;
						this.TROUBLE_TYPE = res.data.TROUBLE_TYPE;
						this.IF_COMPANY_DUTY = res.data.IF_COMPANY_DUTY;
						this.IF_FINISH = res.data.IF_FINISH;
						this.IF_ANSWER_USER = res.data.IF_ANSWER_USER;
						this.IF_USER_SATISFIED = res.data.IF_USER_SATISFIED;
						this.IF_TRUE = res.data.IF_TRUE;
						this.GROUP_DEP = res.data.GROUP_DEP;
						var that = this;
						if (STATUS == 0) {
							this.statusdes = '未处理';
						} else if (STATUS == 1) {
							this.statusdes = '已分配';
						} else if (STATUS == 2) {
							this.statusdes = '处理中';
						} else if (STATUS == 5) {
							this.statusdes = '完结';
						} else if (STATUS == 6) {
							this.statusdes = '审核通过';
						} else if (STATUS == 7) {
							this.statusdes = '审核不通过';
						}
						if (that.TROUBLE_SOURCE == 0) {
							that.typeList = [{
								id: 0,
								val: '大空开跳闸'
							}, {
								id: 1,
								val: '复电失败'
							}, {
								id: 2,
								val: '大空开故障'
							}, {
								id: 3,
								val: '电表亮黄灯'
							}, {
								id: 4,
								val: '串户'
							}, {
								id: 5,
								val: '换表'
							}, {
								id: 6,
								val: '其它故障停电'
							}, {
								id: 7,
								val: '其他'
							}];
						} else {
							that.typeList = [{
								id: 8,
								val: '表下空开跳闸'
							}, {
								id: 9,
								val: '表下空开故障'
							}, {
								id: 10,
								val: '室内空开跳闸'
							}, {
								id: 11,
								val: '室内故障'
							}, {
								id: 12,
								val: '核查用电'
							}, {
								id: 13,
								val: '核查串户'
							}, {
								id: 14,
								val: '其他'
							}];
						}
						for (let i = 0; i < that.deptList.length; i++) {
							if (that.deptList[i].id == that.item.GROUP_RECEIVE) {
								that.deptName = that.deptList[i].val;
								break;
							}
						}
						for (let i = 0; i < that.tsTypeList.length; i++) {
							if (that.tsTypeList[i].id == that.item.COMPTYPE) {
								that.tsType = that.tsTypeList[i].val;
								break;
							}
						}
					}
				});
			},
			//审核
			approve(type) {
				var data = {};
				
				if(this.item.STATUS == "1") //当前处于审核节点，需要填写审核处理意见
				{
					if (this.check_receive_view == '' || this.check_receive_view == null) {
						this.$toast({
							text: '请输入审核处理意见！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}			
					data = {
						ghid: this.ghid,
						caseid: this.caseid,
						casetype: this.casetype,
						status: 4,
						token: this.token,
						changedata: [{		
							CHECK_RECEIVE_VIEW: this.check_receive_view
						}],
					}
				}
				var msg = type==6 ? '确定要【审核通过】吗?' : '确定要【审核不通过】吗?';
				this.$confirm(msg, () => {
					
					updateDetailGas(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							
							data = {
								ghid: this.ghid,
								token: this.token,
								caseid: this.caseid,
								casetype: this.casetype,
								status: type,						
								changedata: [{
									CHECK_RECEIVE_VIEW: this.check_receive_view
								}],
							}
							updateDetail(data).then(res => {
								if (res.code == 1 && res.state != 'FAILURE') {
									this.$toast({
										text: '审核成功！',
										type: 'success',
										duration: 1500
									})
									this.getDetail();
								} else {
									this.$toast({
										text: res.MSG,
										type: 'wraning',
										duration: 1500
									})
								}
							});
						} else {
							this.$toast({
								text: res.msg,
								type: 'wraning',
								duration: 1500
							})
						}
					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			getNowTime() {
				var that = this;
				that.item.FIRST_PUNCH_TIME = that.getNowFormatDate();
			},
			//获取当前日期函数
			getNowFormatDate() {
				let date = new Date(),
				year = date.getFullYear(), //获取完整的年份(4位)
				month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
				strDate = date.getDate() // 获取当前日(1-31)
				if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
				if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
				let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				return `${year}-${month}-${strDate} ` + hours + ":" + minutes + ":" + seconds;
			},
			//上传照片
			chooseImg() {
				var that = this;
				var data = {
					url: location.href.split('#')[0]
				}
				console.log('chooseImg==');
				getConfig(data).then(res => {
					if (res.code == 1) {
						wx.config({
							debug: false,
							appId: res.appId, // 必填，公众号的唯一标识
							timestamp: res.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.nonceStr, // 必填，生成签名的随机串
							signature: res.signature, // 必填，签名
							jsApiList: ['chooseImage'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
						});
						wx.ready(function () {
							wx.chooseImage({
								count: 1, // 默认9
								sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
								sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
								success: function (res) {
									console.log('res=1111=='+JSON.stringify(res));
									var localIds  = res.localIds;
									console.log("开始获取工11单详情==="+localIds[0]);
									var imgData = {
										ghid: that.ghid,
										caseid: that.caseid,
										casetype: that.casetype,
										casename: that.item.CASENAME,
										token: that.token,
									}
									wx.getLocalImgData({
										localId: localIds[0].toString(),
										success: function (res) {
											const localData = "data:image/jpeg;base64,"+res.localData;
											
											imgData.base64Img = [localData];
											updateImg(imgData).then(r => {
												
												if(r.state == 'SUCCESS') {													
													that.$toast({
														text: '上传成功！',
														type: 'success',
														duration: 2000
													})
												}
											});
										},
									});
									
								},
								fail(res) {
									
									that.$toast({
										text: JSON.stringify(res),
										type: 'wraning',
										duration: 1500,
									});
								}
							});
						});
					}
				});
			},
			//获取组织结构
			getOrgMember() {
				var data = {
					ghid: this.ghid,
					token: this.token
				}
				getOrgMember(data).then(res => {
					if (res.code == 1) {
						this.orgList = res.data
						for (var i = 0; i < this.orgList.length; i++) {
							if(this.orgList[i].org_code == '101006001001') {
								var member = this.orgList[i].member;
								var flag = false;
								for (var j = 0; j < member.length; j++) {
									if (this.ghid == member[j].rel_ghid) {
										this.job = member[j].job;
										flag = true;
										break;
									}
								}
								if (flag) {
									break;
								}								
							}
						}
						// this.job = 201;
						console.log('res.data=' + JSON.stringify(res.data));
					}
				});
			},
			//点击打卡
			cardClick() {
				var that = this;
				console.log('that.address==' + that.address);
				console.log('that.lat==' + that.lat);
				console.log('that.lng==' + that.lng);
				if (that.address == '') {
					that.$toast({
						text: '请获取地点！',
						type: 'wraning',
						duration: 1500
					});
					return false;
				}
				that.$confirm('确定要打卡吗？', () => {
					console.log('这是确定事件');
					var data = {
						ghid: that.ghid,
						token: that.token,
						caseid: that.caseid,
						casetype: that.casetype,
						lat: that.lat,
						lng: that.lng,
						address: that.address
					}
					cardSign(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							that.$toast({
								text: '打卡成功！',
								type: 'success',
								duration: 1500
							})
							that.item.PUNCH_ADDR = that.address;
						} else {
							that.$toast({
								text: res.MSG,
								type: 'wraning',
								duration: 1500
							})
						}

					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			//获取当前位置
			getLocation() {
				var that = this;
				var data = {
					url: location.href.split('#')[0]
				}
				getConfig(data).then(res => {
					if (res.code == 1) {
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
							appId: res.appId, // 必填，公众号的唯一标识
							timestamp: res.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.nonceStr, // 必填，生成签名的随机串
							signature: res.signature, // 必填，签名
							jsApiList: ['getLocation',
							'chooseImage'], //必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
						});
						wx.ready(function() {
							wx.getLocation({
								type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
								success: function(res) {
									console.log('res====111========' + JSON.stringify(res));
									//                                    that.process_result = JSON.stringify(res);
									that.lat = Math.round(res.latitude * 1000000) / 1000000;
									that.lng = Math.round(res.longitude * 1000000) / 1000000;
									getAddress({
										latitude: that.lat,
										longitude: that.lng
									}).then(r => {
										console.log(r.address);
										that.address = r.address;
									});
								},
								fail: function(res) {
									console.log('res=====22=======' + JSON.stringify(res));
								}
							});
						});
					}
				});
			},

			//接单
			jieDan() {
				this.$confirm('确定要接单吗？', () => {
					var data = {
						ghid: this.ghid,
						token: this.token,
						caseid: this.caseid,
						casetype: this.casetype,
						status: 0
					}
					updateDetail(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							this.$toast({
								text: '接单成功！',
								type: 'success',
								duration: 1500
							})
							this.getDetail();
						} else {
							this.$toast({
								text: res.msg,
								type: 'wraning',
								duration: 1500
							})
						}
					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			tel: function(mobile) {
				console.log(mobile);
				window.location.href = 'tel://' + mobile;
			},
			onCopy: function() {
				var that = this;
				that.$toast({
					text: '复制成功！',
					type: 'success',
					duration: 1500
				})
			},
			onError: function() {
				var that = this;
				that.$toast({
					text: '复制失败！',
					type: 'wraning',
					duration: 1500
				})
			},
			//保存
			saveDan() {
				var that = this;
				var data = {};
				// if(that.item.STATUS == "1") //当前处于审核节点，需要填写审核处理意见
				// {
				// 	if (that.check_receive_view == '' || that.check_receive_view == null) {
				// 		that.$toast({
				// 			text: '请输入审核处理意见！',
				// 			type: 'wraning',
				// 			duration: 1500
				// 		});
				// 		return false;
				// 	}			
				// 	data = {
				// 		ghid: this.ghid,
				// 		caseid: this.caseid,
				// 		casetype: this.casetype,
				// 		status: 4,
				// 		token: this.token,
				// 		changedata: [{		
				// 			CHECK_RECEIVE_VIEW: this.check_receive_view
				// 		}],
				// 	}
				// }
				// if(that.item.STATUS == "6") //当前处于工单处理节点，需要填写工单处理细节
				// {
					
					if (that.IF_COMPANY_DUTY === '' || that.IF_COMPANY_DUTY == null) {
						that.$toast({
							text: '请选择是否公司责任！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					console.log('that.IF_FINISH==='+that.IF_FINISH);
					if (that.IF_FINISH != 0 && (that.IF_FINISH == '' || that.IF_FINISH == null)) {
						that.$toast({
							text: '请选择是否办结！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.IF_ANSWER_USER != 0 && (that.IF_ANSWER_USER == '' || that.IF_ANSWER_USER == null)) {
						that.$toast({
							text: '请选择是否已答复用户！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.IF_USER_SATISFIED != 0 && (that.IF_USER_SATISFIED == '' || that.IF_USER_SATISFIED == null)) {
						that.$toast({
							text: '请选择用户是否满意！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.item.USER_VIEW == '' || that.item.USER_VIEW == null) {
						that.$toast({
							text: '请输入用户意见！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.GROUP_DEP === '' || that.GROUP_DEP == null) {
						that.$toast({
							text: '请选择处理部门！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.IF_TRUE != 0 && (that.IF_TRUE === '' || that.IF_TRUE == null)) {
						that.$toast({
							text: '请选择是否属实！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					if (that.process_result == '' || that.process_result == null) {
						that.$toast({
							text: '请输入处理结果！',
							type: 'wraning',
							duration: 1500
						});
						return false;
					}
					data = {
						ghid: this.ghid,
						caseid: this.caseid,
						casetype: this.casetype,
						status: 4,
						token: this.token,
						changedata: [{
							GROUP_DEP: this.GROUP_DEP,
							IF_ANSWER_USER: this.IF_ANSWER_USER,
							IF_COMPANY_DUTY: this.IF_COMPANY_DUTY,
							IF_FINISH: this.IF_FINISH,
							IF_TRUE: this.IF_TRUE,
							USER_VIEW: this.item.USER_VIEW,
							FIRST_PUNCH_TIME: this.item.FIRST_PUNCH_TIME,
							IF_USER_SATISFIED: this.IF_USER_SATISFIED,
							PROCESS_RESULT: this.process_result
						}],
					}
				// }
				
				that.btnDisabled = true;
				updateDetailGas(data).then(res => {
					if (res.code == 1 && res.state != 'FAILURE') {
						this.$toast({
							text: '保存成功！',
							type: 'success',
							duration: 1500
						})
					} else {
						this.$toast({
							text: res.msg,
							type: 'wraning',
							duration: 1500
						})
					}
					setTimeout(function() {
						that.btnDisabled = false;
					}, 2500);
				});
			},

			//退单
			tuiDan() {
				this.$confirm('确定要退单吗？', () => {
					var data = {
						ghid: this.ghid,
						token: this.token,
						caseid: this.caseid,
						casetype: this.casetype,
						status: 1
					}
					updateDetail(data).then(res => {
						if (res.code == 1 && res.state != 'FAILURE') {
							this.$toast({
								text: '退单成功！',
								type: 'success',
								duration: 1500
							});
							this.getDetail();
						} else {
							this.$toast({
								text: res.msg,
								type: 'wraning',
								duration: 1500
							})
						}
					});
				}, () => {
					console.log('这是取消事件');
				})
			},
			//转单
			changePeople() {
				this.getOrgMember();
				this.showPop = true;
			},
			//完单
			endDan() {
				var that = this;
				that.getDetail();
				console.log('process_result=' + that.process_result);				
				if (that.process_result == '' || that.process_result == null) {
					that.$toast({
						text: '请输入处理结果！',
						type: 'wraning',
						duration: 1500
					});
					return false;
				}
				var data = {
					ghid: this.ghid,
					token: this.token,
					caseid: this.caseid,
					casetype: this.casetype,
					status: 4,
					changedata: [{
						PROCESS_RESULT: this.process_result
					}],
				}
				updateDetailGas(data).then(res => {
					if (res.code == 1 && res.state != 'FAILURE') {
						data.status = 2;
						this.$confirm('确定要完单吗？', () => {
							updateDetailGas(data).then(res => {
								if (res.code == 1 && res.state != 'FAILURE') {
									this.$toast({
										text: '完单成功！',
										type: 'success',
										duration: 1500
									});
									this.getDetail();
								} else {
									this.$toast({
										text: res.msg,
										type: 'wraning',
										duration: 1500
									})
								}
							});
						}, () => {
							console.log('这是取消事件');
						})
					}
				});

			},
			//选择对应的故障源
			selectSource(idx) {
				if(idx == 1) {
					console.log('========='+this.IF_TRUE);
					if(this.IF_TRUE == 1) {
						this.process_result = '[投诉属实]属XX公司原因/开发商或物业原因/用户原因。一、调查过程:XX年XX月XX日XX时XX分首次联系用户。[用户.户号:XXXXXXXXX]经XX部门/班组XX (工作人员姓名)通过XXX电话/现场与用户联系并调查处理，用户反映情况确实存在，造成该情况的原因是:XXXXXXXXXXXXXXXXXXXXXXXXXX。'+
						'二、调查结论及采取措施:通过上述调查，此投诉确实属实，已采取XX措施，解决用户问题。三、用户意见: XX部门XX (工作人员姓名)，通过电话XXX/现场与用户解释，于X月X日X时，与XX用户电话XXX (如不是投诉本人则描述与投诉人关系，电话)联系解释/现场与用户解释，用户表示XX (满意/不满意、认可/不认可、不评价)。';
					}else {
						this.process_result = '[投诉不属实]非XX公司原因，系XX原因。一、调查过程: XX年XX月XX日XX时XX分首次联系用户。经XX部门/班组XX (工作人员姓名)通过XXX电话/现场与用户联系并调查处理，实际情况: XX.二、用户意见:XX部门XX (工作人员姓名)，通过电话(工作人员电话) XXX于X月X日X时，'+
						'与XX用户电话: XXX (如不是投诉本人则描述与投诉人关系，电话)联系解释/现场与用户解释，用户s表示XX (满意/不满意、认可/不认可、不评价)。';
					}
				}
				console.log('idx======' + idx);
			},
			//选择对应的组织
			selectOrg() {
				this.memList = this.orgItem.member;
			},
			//选择对应的人员
			selectMem() {
				console.log('this.rel_ghid' + this.rel_ghid);
			},
			//取消时隐藏弹窗
			hidePop() {
				this.showPop = false;
			},
			//提交弹出页
			submitPop() {
				var data = {
					ghid: this.ghid,
					token: this.token,
					caseid: this.caseid,
					casetype: this.casetype,
					status: 3,
					transfer_ghid: this.rel_ghid,
				}
				updateDetail(data).then(res => {
					if (res.code == 1 && res.state != 'FAILURE') {
						this.showPop = false;
						this.$toast({
							text: '转派成功！',
							type: 'success',
							duration: 1500
						});
						this.getDetail();
					} else {
						this.showPop = false;
						this.$toast({
							text: res.msg,
							type: 'wraning',
							duration: 2500
						})
					}
				});
			},
			//返回上一页
			back() {
				var that = this;
				if (that.fromHome == 1) {
					that.$router.push({
						name: 'home'
					})
				} else {
					that.$router.push({
						name: 'order',
						query: {
							status: that.preStatus,
							keyword: that.keyword
						}
					})
				}
				//                this.$router.go(-1);//返回上一层
			},
		},
		filters: {
			formatDate: function(value) {
				return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
			},

			formatTime: function(value) {
				return value.substring(0, 2) + ':' + value.substring(2, 4) + ':' + value.substring(4, 6)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
	@import "../assets/css/bootstrap.min.css";
	@import "../assets/css/style.css";
	@import "../assets/css/swiper-bundle.min.css";

	.imgadd {
		flex: 1;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		margin-left: 10px;
	}

	.imgadd>div {
		position: relative;
	}

	.imgadd img {
		width: 100%;
		height: auto;
		position: relative;
		z-index: 10;
		min-height: 60px;
	}

	.imgadd img.close {
		width: 20px;
		height: 20px !important;
		min-height: 20px !important;
		position: absolute;
		top: -5px;
		right: -5px;
		background: #f00;
		border-radius: 100px;
		z-index: 20;
		opacity: 1;
	}

	.imgadd button {
		border: 1px #eee solid;
		border-radius: 4px;
		background: #FAFAFA;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.imgadd button img {
		width: 30px;
		height: 30px !important;
		min-height: 30px !important;
	}
</style>
