import request from '@/utils/request'

// 登录方法
export function login(data){
  return request[0]({
    url: '/user/login',
    method: 'post',
    data: data
  })
}
//获取openid
export function getOpenid(data){
    return request[1]({
        url: '/wx/openid',
        method: 'post',
        data: data
    })
}

//获取config
export function getConfig(data){
    return request[1]({
        url: '/wx/config',
        method: 'post',
        data: data
    })
}

//获取地址
export function getAddress(data){
    return request[1]({
        url: '/wx/address',
        method: 'post',
        data: data
    })
}


//发送短信
export function send(data){
    return request[1]({
        url: '/wx/send',
        method: 'post',
        data: data
    })
}

