<template>
  <div id="header" class="bg-white border-bottom" v-if="false">
    <a class="back" v-if="isBack == 1" @click="back"></a>
    <strong>{{ title }}</strong>
  </div>
</template>

<script>
    export default {
        name: 'CommonHeader',
        props: {
            title: String,
            isBack: String,
        },
        methods: {

            //返回上一页
            back(){
                this.$router.go(-1);//返回上一层
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
