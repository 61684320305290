<template>
    <div id="big_box">
        <!-- 中间内容区域  高度自适应 -->
        <div id="sention">
            <!-- 服务内容 -->
            <!-- 任务单详情 -->
            <div class="order_box bg-white">
                <ul>

                    <li class="textarea star up">
                        <span class="t">结果</span>
                        <textarea v-model="process_result" id="" placeholder="请输入处理结果"></textarea>
                    </li>
                    <li class="textarea star up">
                        <span class="t">地址</span>
                        <textarea v-model="address" id="" placeholder="请输入处理结果"></textarea>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk';
    import {getConfig,getAddress} from "@/api/login";
    export default {
        data() {
            return {
                address: '',
                process_result: '',
            }
        },
        mounted () {
            var that = this;
            that.getLocation();
        },
        methods: {
            getLocation() {
                var that = this;
                var data = {
                    url: location.href.split('#')[0]
                }
                getConfig(data).then(res => {
                    if (res.code == 1) {
                        wx.config({
                            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.appId, // 必填，公众号的唯一标识
                            timestamp: res.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.nonceStr, // 必填，生成签名的随机串
                            signature: res.signature, // 必填，签名
                            jsApiList: ['getLocation'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
                        });
                        console.log('2222222222222222');
                        wx.ready(function () {
                            wx.getLocation({
                                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                                success: function (res) {
                                    console.log('3333');
                                    console.log('res============'+res);
                                    that.process_result = JSON.stringify(res);
                                    getAddress({latitude:res.latitude,longitude:res.longitude}).then(res => {
                                        console.log(res.address);
                                        that.address = res.address;
                                    });
                                },
                                fail: function (res) {
                                    console.log('4444');
                                    console.log('res=====22======='+JSON.stringify(res));
                                    that.process_result = JSON.stringify(res);
                                }
                            });
                        });
                    }
                });
            },
        }
    }
</script>

<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>
