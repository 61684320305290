<!-- header -->
<template>
    <div id="big_box">
        <CommonHeader title="房间管理" isBack="1"/>


        <!-- 中间内容区域  高度自适应 -->
        <div class="wd_list">
            <router-link to="/otherWeb?title=电力营业网点&url=http://www.xgtzgysy.com/fwzn/dlzn/" class="dl">
                <span>电力营业网点</span>
                <img src="@/assets/images/icon_wd01.png">
            </router-link>
            <router-link to="/otherWeb?title=热力营业网点&url=http://www.xgtzgysy.com/fwzn/rlzn/" class="rl">
                <span>热力营业网点</span>
                <img src="@/assets/images/icon_wd02.png">
            </router-link>
            <router-link to="/otherWeb?title=燃气营业网点&url=http://www.xgtzgysy.com/fwzn/rqzn/" class="rq">
                <span>燃气营业网点</span>
                <img src="@/assets/images/icon_wd03.png">
            </router-link>
        </div>
    </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    export default {
        name: 'login',
        components: {
            CommonHeader
        },
        data () {
            return {
                ghid: '8600',
                pwd: 'UltraCRM@2020',
                openid: 'asdfadasdf',
                winUrl: '',
                uploadUrl: process.env.VUE_APP_BASE_API,
                msg: 'Welcome to Your Vue.js App'
            }
        },
        mounted () {

        },
        methods: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>
