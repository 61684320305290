import Vue from 'vue'
import App from './App.vue'
import router from './router'
import toastRegistry from './components/toast'
import alert from './components/Alert'
import axios from 'axios'
import jquery from './components/jquery'  // 此处为修改内容
import VueScroller from 'vue-scroller'
import VueClipboard from 'vue-clipboard2';
import Vconsole from "vconsole"; //开发环境使用，生产环境自动取消
if (process.env.NODE_ENV == "development") { new Vconsole(); }

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }else {
        document.title = '兴港公用事业客服中心'
    }
    next()
})

Vue.config.productionTip = false
Vue.use(toastRegistry)
Vue.use(alert)  // 注意，Vue.use方法必须在new Vue之前被调用
Vue.use(VueScroller)
Vue.prototype.$http = axios
//注册到vue原型上
Vue.use(VueClipboard)

new Vue({
    router,
    jquery, // 此处为修改内容
    render: h => h(App),
}).$mount('#app')
