import request from '@/utils/request'

// 获取个人信息
export function getInfo(data){
  return request[0]({
    url: '/case/modify/get_personal_info',
    method: 'post',
    data: data
  })
}
