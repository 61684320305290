<!-- header -->
<template>
  <div id="big_box">
    <CommonHeader title="房间详情" isBack="1"/>
    <!-- 中间内容区域  高度自适应 -->
    <div id="sention">


      <!-- 电费详情 -->
      <div class="order_box bg-white h_box">
        <h5>
          <i class="rwd"></i>
          <span>基本详情</span>
        </h5>
        <ul>
          <li>
            <span class="t">户号</span>
            <span class="c">张三</span>
          </li>
          <li>
            <span class="t">户名</span>
            <span class="c">19856383023</span>
          </li>
          <li>
            <span class="t">面积</span>
            <span class="c">120</span>
          </li>
          <li>
            <span class="t">户型</span>
            <span class="c">三室两厅</span>
          </li>
          <li>
            <span class="t">地址</span>
            <span class="c">北京市宣武区站前北街(西门铁路桥东大红果向南30米路西)</span>
          </li>
        </ul>
      </div>


    </div>


    <!-- footer -->
    <div class="footer_btn">
      <button class="back">返回</button>
    </div>
  </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    export default {
        name: 'login',
        components: {
            CommonHeader
        },
        data () {
            return {
                ghid: '8600',
                pwd: 'UltraCRM@2020',
                openid: 'asdfadasdf',
                winUrl: '',
                uploadUrl: process.env.VUE_APP_BASE_API,
                msg: 'Welcome to Your Vue.js App'
            }
        },
        mounted () {
            if (!window.localStorage.getItem('openId')) { // 如果缓存localStorage中没有微信openId，则需用code去后台获取
//                this.getCodeFromUrl()
            } else {
                console.log(222);
            }
        },
        methods: {

            //返回上一页
            back(){
                this.$router.go(-1);//返回上一层
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
  @import "../assets/css/bootstrap.min.css";
  @import "../assets/css/style.css";
  @import "../assets/css/swiper-bundle.min.css";
</style>
