<!-- header -->
<template>
    <div id="big_box">
        <!--<CommonHeader title="设备列表"/>-->
        <!-- tab -->
        <div class="tab bg-white">
            <span v-bind:class="status == 1 ? 'active' : ''" @click="tabTap(1)">待接单</span>
            <span v-bind:class="status == 2 ? 'active' : ''" @click="tabTap(2)">进行中</span>
            <span v-bind:class="status == 5 ? 'active' : ''" @click="tabTap(5)">已完成</span>
        </div>
        <!-- so -->
        <div class="so mb-3">
            <input type="text" placeholder="请输入关键字" v-model="keyword" @keypress="searchList">
        </div>
        <!-- 中间内容区域  高度自适应 -->
        <div id="sention">
            <scroller :on-refresh="onRefresh" :on-infinite="onInfiniteLoad" ref="myScroller" style="position: inherit;">
                <!-- 最新工单 -->
                <div class="new_order bg-white mt-0" ref='wrapper'>
                    <!-- content goes here -->
                    <div class="list">
                        <div class="no_data" v-if="list.length == 0">
                            <img src="@/assets/images/no_data.png">
                            <span>暂无数据</span>
                        </div>
                        <a v-for="item in list" :key="item.CASEID" @click="openDetail(item.CASEID,item.CASETYPE)">
                            <div v-bind:class="item.STATUS == 1 ? 'state dai' : (item.STATUS == 2 ? 'state jing' : 'state yi')">
                                <span>{{item.STATUS == 1 ? '待' : (item.STATUS == 2 ? '进' : '已')}}</span></div>

                            <div class="text">
                                <p>{{item.CASENAME}}</p>
                                <small>工单号：{{item.CASEID}}</small>
                                <!--<small>地址：{{item.ADDRESS}}</small>-->
                                <small>工单时间：2020-10-09 14:57</small>
                            </div>
                        </a>
                    </div>
                </div>
            </scroller>
        </div>

        <div id="footer" class="bg-white border-top">
            <router-link to="/home?fromLogin=1" class="home">
                <i></i>
                <small>首页</small>
            </router-link>
            <router-link to="/order" class="order active">
                <i></i>
                <small>工单</small>
            </router-link>
            <router-link to="/my" class="user">
                <i></i>
                <small>我的</small>
            </router-link>
        </div>
    </div>
</template>

<script>
    //    import CommonHeader from '../components/CommonHeader.vue'
    import {getValue} from '@/utils/auth'
    import {getList} from "@/api/order";

    export default {
        name: 'order',
        components: {
//            CommonHeader
        },
        data () {
            return {
                isLogin: getValue('isLogin'),
                ghid: getValue('ghid'),
                keyword: '',
                token: getValue('token'),
                status: 1,
                pageNo: 1,
                pageSize: 5,
                startFlag: 0,
                list: [],
                count: 0,
                hasMore: true
            }
        },
        mounted () {
            var that = this;
            that.startFlag = 0;
            that.status = that.$router.history.current.query.status == undefined ? 1 : that.$router.history.current.query.status;
            that.keyword = that.$router.history.current.query.keyword == undefined ? 1 : that.$router.history.current.query.keyword;
            if (this.isLogin) {
//                this.getList();
            }
        },
        methods: {

            //获取列表
            getList() {
                if (this.hasMore) {
                    var data = {
                        ghid: this.ghid,
                        keyword: this.keyword,
                        token: this.token,
                        status: this.status,
                        pageNo: this.pageNo,
                        pageSize: this.pageSize
                    }
                    console.log("开始获取工单列表pageNo..." + this.pageNo)
                    getList(data).then(res => {
                        if (res.code == 1) {
                            if (this.pageNo == 1) {
                                this.list = res.data;
                            } else {
                                this.list = this.list.concat(res.data);
                            }
                            this.count = res.count;
                            this.hasMore = this.count == this.list.length ? false : true;
                            this.startFlag = 1;
                        } else {
                            this.list = [];
                            this.hasMore = false;
                        }
                    });
                }
            },
            //检索事件
            searchList(event) {
                if (event.keyCode == 13) { //如果按的是enter键 13是enter
                    this.pageNo = 1
                    this.hasMore = true
                    this.getList()
                }
            },
            //工单详情页面
            openDetail(CASEID, CASETYPE) {
                var that = this;
                console.log('openDetail================');
                console.log('CASEID=' + CASEID);
                console.log('keyword=' + that.keyword);
                that.$router.push({
                    name: 'orderDetail',
                    query: {
                        CASEID: CASEID,
                        CASETYPE: CASETYPE,
                        status: that.status,
                        fromHome: 0,
                        keyword: that.keyword,
                    }
                })
            },
            //头部标签栏
            tabTap(idx) {
                console.log(idx)
                if (idx == 0) {
                    this.status = 1
                } else {
                    this.status = idx
                }
                this.startFlag = 0;
                this.pageNo = 1
                this.hasMore = true
                this.getList()
            },
            //下拉刷新
            onRefresh(done) {
                console.log('下拉刷新' + done);
                setTimeout(() => {
                    this.pageNo = 1;
                    this.list = [];
                    this.hasMore = true;
                    this.getList();
                    done(true)
                }, 500)
            },
            // 全部订单上拉加载
            onInfiniteLoad(done) {
                console.log('上拉加载');
                if(this.hasMore) {
                    if(this.startFlag == 1) {
                        this.pageNo = this.pageNo + 1
                    }
                    this.getList()
                    setTimeout(() => {
                        done(true)
                    }, 500)
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";

</style>
