import request from '@/utils/request'

// 获取列表
export function getList(data){
  return request[0]({
    url: '/case/search/case_list',
    method: 'post',
    data: data
  })
}
//获取详情
export function getDetail(data){
    return request[0]({
        url: '/case/search/case_edit',
        method: 'post',
        data: data
    })
}
//更新工单
export function updateDetail(data){
    return request[0]({
        url: '/case/modify/modify_case',
        method: 'post',
        data: data
    })
}
//更新燃气工单
export function updateDetailGas(data){
    return request[0]({
        url: '/case/modify/modify_case_gas',
        method: 'post',
        data: data
    })
}
//上传附件
export function updateImg(data){
    return request[0]({
        url: '/case/case_attach/upload',
        method: 'post',
        data: data
    })
}
//获取已上传附件列表件接口
export function getImgList(data){
    return request[0]({
        url: '/case/case_attach/get_list',
        method: 'post',
        data: data
    })
}
//删除附件接口
export function deleteImg(data){
    return request[0]({
        url: '/case/case_attach/attach_delete',
        method: 'post',
        data: data
    })
}
//打卡接口
export function cardSign(data){
    return request[0]({
        url: '/case/clockIn',
        method: 'post',
        data: data
    })
}
//获取组织结构
export function getOrgMember(data){
    return request[0]({
        url: '/org_group/get_org_member',
        method: 'post',
        data: data
    })
}

//获取用户组成员
export function getGroupMember(data){
    return request[0]({
        url: '/cust_group/get_cust_member',
        method: 'post',
        data: data
    })
}