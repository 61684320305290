<template>
    <div id="big_box">
        <div id="header" class="bg-white border-bottom" v-if="false">
            <a class="back" @click="back"></a>
            <strong>{{title}}</strong>
        </div>
        <div id="sention">
            <iframe id="iFrame" frameborder="0" v-bind:src="url"></iframe>
        </div>
    </div>
</template>


<script>
    export default {
        data () {
            return {
                title: '',
                url: ''
            }
        },
        mounted(){
            /**
             * iframe-宽高自适应显示
             */
            function changeMobsfIframe() {
                const mobsf = document.getElementById('iFrame');
                const deviceWidth = document.body.clientWidth;
                const deviceHeight = document.body.clientHeight;
                mobsf.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
                mobsf.style.height = (Number(deviceHeight)) + 'px'; //数字是页面布局高度差
            }
            changeMobsfIframe()
            window.onresize = function () {
                changeMobsfIframe()
            }
            this.title = this.$router.history.current.query.title;
            this.url = this.$router.history.current.query.url;
            console.log('this.title='+this.title)
            console.log('this.url='+this.url)
//            this.getUrl(this.url)
        },
        methods: {


            //返回上一页
            back(){
                this.$router.go(-1);//返回上一层
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>