import axios from 'axios'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'

axios.defaults.headers['Content-Type'] = 'application/json'
//axios.defaults.headers['Timestamp'] = new Date().getTime()

function request() {
    // 创建axios实例
    const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout: 10000
    })
    const service1 = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        // baseURL: 'http://localhost:8889',
        baseURL: process.env.VUE_APP_BASE_API_WX,
        // 超时
        timeout: 10000
    })
    // request拦截器
    service.interceptors.request.use(
        config => {
            var token = getToken();
            console.log('token='+JSON.stringify(process.env)+"==="+token);
            config.headers['Timestamp'] = new Date().getTime()
            if (getToken()) {
                config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
            }
            return config
        },
        error => {
            Promise.reject(error)
        }
    )

    // 响应拦截器
    service.interceptors.response.use(res => {
            // const code = res.data.code
            console.log('res='+res);
            //
            // if (code === 401) {
            //
            // } else {
              return res.data
            // }
        },
        error => {
            console.log('22=err' + error)
            Cookies.set('isLogin', false);
            localStorage.setItem('isLogin',false);
            return Promise.reject(error)
        }
    )
    // request拦截器
    service1.interceptors.request.use(
        config => {
            if (getToken()) {
                config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
            }
            return config
        },
        error => {
            console.log("33="+error)
            Promise.reject(error)
        }
    )

    // 响应拦截器
    service1.interceptors.response.use(res => {
            // const code = res.data.code
            //
            // if (code === 401) {
            //
            // } else {
              return res.data
            // }
        },
        error => {
            console.log('44===err' + error)

            return Promise.reject(error)
        }
    )
    return [service,service1]
}


export default request()
