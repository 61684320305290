<!-- header -->
<template>
    <div id="big_box">
        <CommonHeader title="房间管理" isBack="1"/>


        <!-- 中间内容区域  高度自适应 -->
        <div class="wd_list">
            <a href="https://www.wjx.cn/vm/PpC5UDZ.aspx" class="dl">
                <span>居民用热意向调研问卷</span>
                <img src="@/assets/images/icon_wd04.png">
            </a>
            <!--<a href="https://www.wjx.cn/m/83569871.aspx" class="dl">-->
                <!--<span>加油站市场调查问卷</span>-->
                <!--<img src="@/assets/images/icon_wd02.png">-->
            <!--</a>-->
        </div>
    </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    export default {
        name: 'login',
        components: {
            CommonHeader
        },
        data () {
            return {
                ghid: '8600',
                pwd: 'UltraCRM@2020',
                openid: 'asdfadasdf',
                winUrl: '',
                uploadUrl: process.env.VUE_APP_BASE_API,
                msg: 'Welcome to Your Vue.js App'
            }
        },
        mounted () {

        },
        methods: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>
