<!-- header -->
<template>
    <div id="big_box">
        <CommonHeader title="个人中心"/>
        <!-- 中间内容区域  高度自适应 -->
        <div id="sention">
            <!-- 我的信息 -->
            <div class="user_box">
                <div class="face" v-if="headImg == 'null' || headImg == undefined || isLogin == 'false'"><img src="@/assets/images/icon_face.png"></div>
                <div class="face" v-if="headImg != 'null' && headImg != '' && headImg != undefined"><img :src="headImg"></div>
                <div class="text">
                    <strong>{{!isLogin || isLogin=='false' ? '未登录' : item.ghid}}</strong>
                    <!--<button @click="bindWx" v-if="isLogin">绑定微信</button>-->
                    <button @click="login" v-if="!isLogin || isLogin=='false'">我要登录</button>
                </div>
                <img src="@/assets/images/icon_sbg.png" class="bg">
            </div>
            <!-- list -->
            <div class="user_list">
                <router-link to="/order" class="wdgd mb-3" v-if="isLogin=='true'">
                    <i></i>
                    <span>我的工单</span>
                </router-link>
                <a href="https://mp.weixin.qq.com/s/z4CdFRBkJ-LCK7eDwLMjYw" target="_Self" class="lxwm">
                    <i></i>
                    <span>关于我们</span>
                </a>
                <router-link to="/otherWeb?title=联系我们&url=http://www.xgtzgysy.com/lxwm/" class="lxkf" >
                    <i></i>
                    <span>联系我们</span>
                </router-link>
                <a class="exit" @click="quitLogin" v-if="!(!isLogin || isLogin=='false')">
                    <i></i>
                    <span>退出</span>
                </a>
            </div>
        </div>
        <!-- footer -->
        <div id="footer" class="bg-white border-top">
            <router-link to="/home?fromLogin=1" class="home">
                <i></i>
                <small>首页</small>
            </router-link>
            <router-link to="/order" class="order" v-if="isLogin=='true'">
                <i></i>
                <small>工单</small>
            </router-link>
            <router-link to="/my" class="user active">
                <i></i>
                <small>我的</small>
            </router-link>
        </div>
    </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    import { getValue, setValue } from '@/utils/auth'
    import { getInfo } from "@/api/my";

    export default {
        name: 'my',
        components: {
            CommonHeader
        },
        data () {
            return {
                ghid: getValue('ghid'),
                pwd: getValue('pwd'),
                token: getValue('token'),
                item: {},
                headImg: getValue('headImg'),
                isLogin: getValue('isLogin')== undefined ? false : getValue('isLogin'),
                appid: process.env.VUE_APP_APPID
            }
        },
        mounted () {
            console.log('this.headImg='+this.headImg);
            if(this.isLogin == undefined) {
                this.isLogin = false;
            }
            if(this.isLogin) {
                this.getInfo();
            }
        },
        methods: {
            //获取个人信息
            getInfo() {
                var data = {
                    ghid: this.ghid,
                    pwd: this.pwd,
                    token: this.token
                }
                console.log("获取个人信息==="+this.ghid)
                getInfo(data).then(res =>{
                    if(res.code == 0) {
                        this.item = res.data
                        if(res.state == "FAILURE") {
                            setValue('isLogin', false);
                            this.isLogin = false;
                        }
                    }else {
                        setValue('isLogin', false);
                        this.isLogin = false;
                    }
                });
            },
            //我要登录
            login() {
				
				var ua = navigator.userAgent.toLocaleLowerCase();
						
                var local = window.location.href;
                var redirectUrl = '';
                if(local.indexOf('?')!=-1) {
                    redirectUrl = local.substring(0,local.indexOf('?')-1)+'/#/login';
                }else {
                    redirectUrl = local.substring(0,local.indexOf('/#'))+'/#/login';
                }
				
                // if(process.env.VUE_APP_FLAG == '0') {
				if(ua.indexOf('micromessenger') == -1){
                    this.$router.push({
                        name:'login'
                    })
                }else {
                    console.log('redirectUrl==='+redirectUrl);
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_userinfo`
                }
            },
            bindWx() {
              //绑定微信
            },
            //退出登录
            quitLogin() {
                var that = this;
                that.$confirm('确定要退出吗？', () => {
                    console.log('这是确定事件');
//                    removeValue('token');
//                    removeValue('ghid');
//                    removeValue('pwd');
                    setValue('isLogin', false);
//                    setValue('userType', 'youke');//当userType为user时，代表使用人员，为youke时代表普通游客
                    that.login();
                }, () => {
                    console.log('这是取消事件');
                })
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>
