<template>
    <div id="big_box">
        <div id="header" class="bg-white border-bottom" v-if="false">
            <a class="back" @click="back"></a>
            <strong>{{title}}</strong>
        </div>
        <div id="sention">
            <iframe id="iFrame" frameborder="0"></iframe>
        </div>
    </div>
</template>


<script>
    import $ from 'jquery'
    export default {
        data () {
            return {
                title: '',
                url: ''
            }
        },
        mounted(){
            /**
             * iframe-宽高自适应显示
             */
            function changeMobsfIframe() {
                const mobsf = document.getElementById('iFrame');
                const deviceWidth = document.body.clientWidth;
                const deviceHeight = document.body.clientHeight;
                mobsf.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
                mobsf.style.height = (Number(deviceHeight)) + 'px'; //数字是页面布局高度差
            }
            changeMobsfIframe()
            window.onresize = function () {
                changeMobsfIframe()
            }
            this.title = this.$router.history.current.query.title;
            this.url = this.$router.history.current.query.url;
            console.log('this.title='+this.title)
            console.log('this.url='+this.url)
            this.getUrl(this.url)
        },
        methods: {
            getUrl(URL){
                let http = (window.location.protocol === 'http:' ? 'http:' : 'https:');
                //调用跨域API
                let realurl = http + '//cors-anywhere.herokuapp.com/' + URL;
                $.ajax({
                    type: 'get',
                    url: realurl,
                    success: function (response) {
                        if (response) {
                            let html = response;
                            html = html.replace(/data-src/g, "src")
                                .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/g, '')
                                .replace(/https/g, 'http');
                            // let html_src = 'data:text/html;charset=utf-8,' + html;
                            let html_src = html;
                            let iframe = document.getElementById('iFrame');
                            iframe.src = html_src;
                            var doc = iframe.contentDocument || iframe.document;
                            //解决微信公众号文章图片的防盗链问题
                            html_src = html_src.replace(/data-src/g, "src")
                            html_src = html_src.replace(/(wx_fmt=gif)|(wx_fmt=png)|(wx_fmt=jpg)|(wx_fmt=jpeg)/g, "")
                            doc.write(html_src);
                            doc.getElementById("js_content").style.visibility = "visible";
                        }
                    },
                    error: function (err) {
                        console.log(err);
                        alert('好像出错了...');
                    }
                });
            },

            //返回上一页
            back(){
                this.$router.go(-1);//返回上一层
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>