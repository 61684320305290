<!-- header -->
<template>
    <div id="big_box">
        <CommonHeader title="工单详情" isBack="1"/>
        <!-- 中间内容区域  高度自适应 -->
        <div id="sention">
            <!-- 联系方式 -->
            <div class="order_box bg-white">
                <h5>
                    <i class="fwnr"></i>
                    <span>客户信息</span>
                </h5>
                <ul>
                    <li v-if="item.CASETYPE == 6 || item.CASETYPE == 7">
                        <span class="t">热力用户卡号</span>
                        <span class="c">{{item.USER_CODE}}</span>
                    </li>
                    <li v-if="item.CASETYPE == 1 || item.CASETYPE == 2 || item.CASETYPE == 5">
                        <span class="t">电力用户表号</span>
                        <span class="c">{{item.WH_METER_NO}}</span>
                    </li>
                    <li class="tel">
                        <span class="t">联系电话</span>
                        <span class="c" @click="tel(item.TEL)">{{item.TEL}}</span>
                        <button v-clipboard:copy="item.TEL"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError">
                            <img src="@/assets/images/icon_copy.png">
                        </button>
                    </li>
                    <li>
                        <span class="t">地址</span>
                        <span class="c">{{item.ADDRESS}}</span>
                    </li>
                </ul>
            </div>
            <!-- 服务内容 -->
            <!-- 任务单详情 -->
            <div class="order_box bg-white">
                <h5>
                    <i class="rwd"></i>
                    <span>工单信息</span>
                </h5>
                <ul>
                    <li>
                        <span class="t">工单名称</span>
                        <span class="c">{{item.CASENAME}}</span>
                    </li>
                    <li>
                        <span class="t">工单类型</span>
                        <span class="c">{{item.ctName}}</span>
                    </li>
                    <li>
                        <span class="t">工单单号</span>
                        <span class="c">{{item.CASEID}}</span>
                    </li>
                    <!--<li>-->
                    <!--<span class="t">派单人工号</span>-->
                    <!--<span class="c">{{item.GHID_DISPATCH}}</span>-->
                    <!--</li>-->
                    <li>
                        <span class="t">派单人姓名</span>
                        <span class="c">{{item.GHID_DIS_NAME}}</span>
                    </li>
                    <li>
                        <span class="t">派单时间</span>
                        <!--<span class="c">{{item.SDATE | formatDate}} {{item.STIME | formatTime}}</span>-->
                        <span class="c">{{item.DISPATCH_DAT}}</span>
                    </li>
                    <!--<li>
                        <span class="t">接单人姓名</span>
                        <span class="c">{{item.GHID_DIS_NAME}}</span>
                    </li>
                    <li>
                        <span class="t">接单时间</span>
                        &lt;!&ndash;<span class="c">{{item.SDATE | formatDate}} {{item.STIME | formatTime}}</span>&ndash;&gt;
                        <span class="c">{{item.DISPATCH_DAT}}</span>
                    </li>-->
                    <li class="textarea up">
                        <span class="t">问题描述</span>
                        <!--{{item.PROBLEM_DES}}-->
                        <!--<span class="c"><input type="text" placeholder="请输入手机号 "></span>-->
                        <!--<span class="c">-->
                        <!---->
                        <!--</span>-->
                        <textarea v-model="item.PROBLEM_DES" disabled="disabled"></textarea>
                    </li>
                    <li class="daka" v-if="item.CASETYPE != 4 && item.CASETYPE != 8">
                        <span class="t">打卡地址</span>
                        <span class="c">{{item.PUNCH_ADDR == null || item.PUNCH_ADDR == '' ? address : item.PUNCH_ADDR}}</span>
                        <!--<span class="c">{{item.ADDRESS}}</span>-->
                        <button @click="cardClick" v-if="item.STATUS != 5"><img src="@/assets/images/icon_lct.png">
                        </button>
                    </li>
                    <li class="star" v-if="item.CASETYPE == 1 || item.CASETYPE == 2 || item.CASETYPE == 5">
                        <span class="t">故障源</span>
                        <span class="c">
                            <!--{{item.PROBLEM_DES}}-->
                            <select v-model="TROUBLE_SOURCE" @change="selectSource" :disabled="item.STATUS==5">
                                <option style="display:none" value="" disabled>请选择</option>
                                <option v-for="it in sourceList" v-bind:key="it.id" :value="it.id">{{it.val}}
                                </option>
                            </select>
                        </span>
                    </li>
                    <li class="star" v-if="item.CASETYPE == 1 || item.CASETYPE == 2 || item.CASETYPE == 5">
                        <span class="t">故障类型</span>
                        <span class="c">
                            <!--{{item.PROBLEM_DES}}-->
                            <select v-model="TROUBLE_TYPE" :disabled="item.STATUS==5">
                                <option style="display:none" value="" disabled>请选择</option>
                                <option v-for="it in typeList" v-bind:key="it.id" :value="it.id">{{it.val}}
                                </option>
                            </select>
                        </span>
                    </li>
                    <!--<li class="star">-->
                    <!--<span class="t">问题描述</span>-->
                    <!--<spsn class="c">-->
                    <!--&lt;!&ndash;{{item.PROBLEM_DES}}&ndash;&gt;-->
                    <!--<select v-model="orgItem" @change="selectOrg">-->
                    <!--<option value="1111">1111</option>-->
                    <!--<option v-for="it in orgList" v-bind:key="it.org_code" :value="it">{{it.org_code}}-->
                    <!--</option>-->
                    <!--</select>-->
                    <!--</spsn>-->
                    <!--</li>-->
                    <li class="textarea star up">
                        <span class="t">处理结果</span>
                        <textarea v-model="process_result" id="" placeholder="请输入处理结果"
                                  :disabled="item.STATUS==5"></textarea>
                    </li>

                    <li v-if="item.STATUS == 2 || item.STATUS == 3">
                        <span class="t">上传图片</span>
                        <div class="imgadd">
                            <!-- <div>
                                <img src="@/assets/images/icon_close.png" class="close" @click='deleteImg(index)'>
                                <img src="@/assets/images/icon_face.png">
                            </div>
                            <div>
                                <img src="@/assets/images/icon_close.png" class="close" @click='deleteImg(index)'>
                                <img src="@/assets/images/img_homebg.png">
                            </div> -->
                            <button @click="chooseImg()">
                                <img src="@/assets/images/Plus.png" mode="widthFix">
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- footer -->
        <div class="footer_btn">
            <!--1-电力抢险抢修工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 1 && (item.STATUS == 1 || item.STATUS == 3)">
                接单
            </button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 1 && item.STATUS == 2" :disabled="btnDisabled">
                保存
            </button>
            <button class="tuidan" @click="tuiDan" v-if="item.CASETYPE == 1 && item.STATUS == 2">退单</button>
            <button class="zhuanpai" @click="changePeople" v-if="item.CASETYPE != 8 && job == 402">转派</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 1 && item.STATUS == 2">完单</button>
            <!--2-电力复电工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 2 && (item.STATUS == 1 || item.STATUS == 3)">
                接单
            </button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 2 && item.STATUS == 2" :disabled="btnDisabled">
                保存
            </button>
            <button class="tuidan" @click="tuiDan" v-if="item.CASETYPE == 2 && item.STATUS == 2">退单</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 2 && item.STATUS == 2">完单</button>
            <!--4-电力业务变更工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 4 && (item.STATUS == 1 || item.STATUS == 3)">
                接单
            </button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 4 && item.STATUS == 2" :disabled="btnDisabled">
                保存
            </button>
            <button class="tuidan" @click="tuiDan" v-if="item.CASETYPE == 4 && item.STATUS == 2">退单</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 4 && item.STATUS == 2">完单</button>
            <!--5-电力非抢修工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 5 && (item.STATUS == 1 || item.STATUS == 3)">
                接单
            </button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 5 && item.STATUS == 2" :disabled="btnDisabled">
                保存
            </button>
            <button class="tuidan" @click="tuiDan" v-if="item.CASETYPE == 5 && item.STATUS == 2">退单</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 5 && item.STATUS == 2">完单</button>

            <!--7-热力抢险抢修工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 7 && (item.STATUS == 1 || item.STATUS == 3)">
                接单
            </button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 7 && item.STATUS == 2" :disabled="btnDisabled">
                保存
            </button>
            <button class="tuidan" @click="tuiDan" v-if="item.CASETYPE == 7 && item.STATUS == 2">退单</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 7 && item.STATUS == 2">完单</button>
            <!--6-热力预约工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 6 && (item.STATUS == 1 || item.STATUS == 3)">
                接单
            </button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 6 && item.STATUS == 2" :disabled="btnDisabled">
                保存
            </button>
            <button class="tuidan" @click="tuiDan" v-if="item.CASETYPE == 6 && item.STATUS == 2">退单</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 6 && item.STATUS == 2">完单</button>

            <!--8-热力营销工单-->
            <button class="jiedan" @click="jieDan" v-if="item.CASETYPE == 8 && (item.STATUS == 1 || item.STATUS == 3)">接单</button>
            <button class="save" @click="saveDan" v-if="item.CASETYPE == 8 && item.STATUS == 2" :disabled="btnDisabled">保存</button>
            <button class="wandan" @click="endDan" v-if="item.CASETYPE == 8 && item.STATUS == 2">完单</button>

            <button class="back" @click="back">返回</button>
        </div>

        <div class="mask-box" v-if="showPop">
            <div class="order_layer">
                <div class="sel">
                    <span>组织<span class="red">*</span></span>
                    <select v-model="orgItem" @change="selectOrg">
                        <option style="display:none" value="" disabled>请选择</option>
                        <option v-for="it in orgList" v-bind:key="it.org_code" :value="it">{{it.org_name}}</option>
                    </select>
                </div>
                <div class="sel">
                    <span>人员<span class="red">*</span></span>
                    <select v-model="rel_ghid" @change="selectMem">
                        <option style="display:none" value="" disabled>请选择</option>
                        <option v-for="it1 in memList" v-bind:key="it1.rel_ghid" :value="it1.rel_ghid">
                            {{it1.rel_name}}
                        </option>
                    </select>
                </div>
                <div class="btns">
                    <button class="cancel" @click="hidePop">取消</button>
                    <button class="seccess" @click="submitPop">确定</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    import {getValue} from '@/utils/auth'
    import {getDetail, getOrgMember, cardSign, updateDetail, updateImg} from "@/api/order";
    import wx from 'weixin-js-sdk';
    import {getConfig, getAddress} from "@/api/login";

    export default {
        name: 'orderDetail',
        components: {
            CommonHeader
        },
        data () {
            return {
                ghid: getValue('ghid'),
                keyword: '',
                caseid: '',
                casetype: '1',
                token: getValue('token'),
                process_result: '',
                fromHome: 0,
                preStatus: 1,
                job: 401,
                lat: '',
                lng: '',
                address: '',
                item: {},
                showPop: false,
                TROUBLE_TYPE: '',
                TROUBLE_SOURCE: '',
                sourceList: [{id: 0, val: '供电侧'}, {id: 1, val: '用电侧'}],
                typeList: [],
                orgList: [],
                btnDisabled: false,
                orgItem: {},
                memList: [],
                rel_ghid: 0
            }
        },
        mounted () {
            var that = this;
            that.caseid = that.$router.history.current.query.CASEID;
            that.casetype = that.$router.history.current.query.CASETYPE;
            that.preStatus = that.$router.history.current.query.status;
            that.fromHome = that.$router.history.current.query.fromHome;
            that.keyword = that.$router.history.current.query.keyword;
            console.log('keyword===='+that.keyword);
            that.getLocation();
            that.getOrgMember();
            that.getDetail();
        },
        methods: {
            //获取工单详情
            getDetail() {
                var data = {
                    ghid: this.ghid,
                    caseid: this.caseid,
                    casetype: this.casetype,
                    token: this.token
                }
                console.log("开始获取工单详情===" + this.caseid)
                getDetail(data).then(res => {
                    if (res.code == 1) {
                        this.item = res.data;
//                        if(res.data.STATUS != 5 || true) {
//                            this.getLocation();
//                        }
                        var CASETYPE = res.data.CASETYPE
                        var ctName = '电力抢险抢修工单'
                        if (CASETYPE == 2) {
                            ctName = '电力复电工单'
                        } else if (CASETYPE == 3) {
                            ctName = '电力预约工单'
                        } else if (CASETYPE == 4) {
                            ctName = '电力业务变更工单'
                        } else if (CASETYPE == 5) {
                            ctName = '电力非抢修工单'
                        } else if (CASETYPE == 7) {
                            ctName = '热力抢险抢修工单'
                        } else if (CASETYPE == 6) {
                            ctName = '热力预约工单'
                        } else if (CASETYPE == 8) {
                            ctName = '热力营销工单'
                        }
                        this.item.ctName = ctName;
                        this.process_result = res.data.PROCESS_RESULT;
                        this.TROUBLE_SOURCE = res.data.TROUBLE_SOURCE;
                        this.TROUBLE_TYPE = res.data.TROUBLE_TYPE;
                        var that = this;
                        if (that.TROUBLE_SOURCE == 0) {
                            that.typeList = [{id: 0, val: '大空开跳闸'}, {id: 1, val: '复电失败'}, {id: 2, val: '大空开故障'}, {
                                id: 3,
                                val: '电表亮黄灯'
                            }, {id: 4, val: '串户'}, {id: 5, val: '换表'}, {id: 6, val: '其它故障停电'}, {id: 7, val: '其他'}];
                        } else {
                            that.typeList = [{id: 8, val: '表下空开跳闸'}, {id: 9, val: '表下空开故障'}, {
                                id: 10,
                                val: '室内空开跳闸'
                            }, {id: 11, val: '室内故障'}, {id: 12, val: '核查用电'}, {id: 13, val: '核查串户'}, {
                                id: 14,
                                val: '其他'
                            }];
                        }
                    }
                });
            },
            //获取组织结构
            getOrgMember() {
                var data = {
                    ghid: this.ghid,
                    token: this.token
                }
                getOrgMember(data).then(res => {
                    if (res.code == 1) {
                        this.orgList = res.data
                        for (var i = 0; i < this.orgList.length; i++) {
                            var member = this.orgList[i].member;
                            var flag = false;
                            for (var j = 0; j < member.length; j++) {
                                if (this.ghid == member[j].rel_ghid) {
                                    this.job = member[j].job;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag) {
                                break;
                            }
                        }
                        console.log('res.data=' + JSON.stringify(res.data));
                    }
                });
            },
            //点击打卡
            cardClick() {
                var that = this;
                console.log('that.address==' + that.address);
                console.log('that.lat==' + that.lat);
                console.log('that.lng==' + that.lng);
                if (that.address == '') {
                    that.$toast({
                        text: '请获取地点！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                that.$confirm('确定要打卡吗？', () => {
                    console.log('这是确定事件');
                    var data = {
                        ghid: that.ghid,
                        token: that.token,
                        caseid: that.caseid,
                        casetype: that.casetype,
                        lat: that.lat,
                        lng: that.lng,
                        address: that.address
                    }
                    cardSign(data).then(res => {
                        if (res.code == 1 && res.state != 'FAILURE') {
                            that.$toast({
                                text: '打卡成功！',
                                type: 'success',
                                duration: 1500
                            })
                            that.getDetail();
                        } else {
                            that.$toast({
                                text: res.MSG,
                                type: 'wraning',
                                duration: 1500
                            })
                        }

                    });
                }, () => {
                    console.log('这是取消事件');
                })
            },
            //上传照片
            chooseImg() {
                var that = this;
                var data = {
                    url: location.href.split('#')[0]
                }
                console.log('chooseImg==');
                getConfig(data).then(res => {
                    if (res.code == 1) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.appId, // 必填，公众号的唯一标识
                            timestamp: res.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.nonceStr, // 必填，生成签名的随机串
                            signature: res.signature, // 必填，签名
                            jsApiList: ['chooseImage'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
                        });
                        wx.ready(function () {
                            wx.chooseImage({
                                count: 1, // 默认9
                                sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                                sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                                success: function (res) {
                                    
									var localIds  = res.localIds;
                                    
									var imgData = {
                                        ghid: that.ghid,
                                        caseid: that.caseid,
                                        casetype: that.casetype,
                                        casename: that.item.CASENAME,
                                        token: that.token
                                    }
                                    wx.getLocalImgData({
                                        localId: localIds[0].toString(),
                                        success: function (res) {
                                            const localData = "data:image/jpeg;base64,"+res.localData;
                                            
                                            imgData.base64Img = [localData]; 
											updateImg(imgData).then(r => {
												
												if(r.state == 'SUCCESS') {													
													that.$toast({
														text: '上传成功！',
														type: 'success',
														duration: 2000
													})
												}
											});
                                        }
                                    });

                                },
                                fail(res) {
                                    
									that.$toast({
                                        text: JSON.stringify(res),
                                        type: 'wraning',
                                        duration: 1500
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //获取当前位置
            getLocation() {
                var that = this;
                var data = {
                    url: location.href.split('#')[0]
                }
                getConfig(data).then(res => {
                    if (res.code == 1) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.appId, // 必填，公众号的唯一标识
                            timestamp: res.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.nonceStr, // 必填，生成签名的随机串
                            signature: res.signature, // 必填，签名
                            jsApiList: ['getLocation','chooseImage'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
                        });
                        wx.ready(function () {
                            wx.getLocation({
                                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                                success: function (res) {
                                    console.log('res====111========' + JSON.stringify(res));
//                                    that.process_result = JSON.stringify(res);
                                    that.lat = Math.round(res.latitude * 1000000) / 1000000;
                                    that.lng = Math.round(res.longitude * 1000000) / 1000000;
                                    getAddress({latitude: that.lat, longitude: that.lng}).then(r => {
                                        console.log(r.address);
                                        that.address = r.address;
                                    });
                                },
                                fail: function (res) {
                                    console.log('res=====22=======' + JSON.stringify(res));
                                }
                            });
                        });
                    }
                });
            },

            //接单
            jieDan() {
                this.$confirm('确定要接单吗？', () => {
                    var data = {
                        ghid: this.ghid,
                        token: this.token,
                        caseid: this.caseid,
                        casetype: this.casetype,
                        status: 0
                    }
                    updateDetail(data).then(res => {
                        if (res.code == 1 && res.state != 'FAILURE') {
                            this.$toast({
                                text: '接单成功！',
                                type: 'success',
                                duration: 1500
                            })
                            this.getDetail();
                        } else {
                            this.$toast({
                                text: res.MSG,
                                type: 'wraning',
                                duration: 1500
                            })
                        }
                    });
                }, () => {
                    console.log('这是取消事件');
                })
            },
            tel: function (mobile) {
                console.log(mobile);
                window.location.href = 'tel://' + mobile;
            },
            onCopy: function () {
                var that = this;
                that.$toast({
                    text: '复制成功！',
                    type: 'success',
                    duration: 1500
                })
            },
            onError: function () {
                var that = this;
                that.$toast({
                    text: '复制失败！',
                    type: 'wraning',
                    duration: 1500
                })
            },
            //保存
            saveDan() {
                var that = this;
                var data = {
                    ghid: this.ghid,
                    token: this.token,
                    caseid: this.caseid,
                    casetype: this.casetype,
                    process_result: this.process_result,
                    status: 4
                }
                if (that.item.CASETYPE == 1 || that.item.CASETYPE == 2 || that.item.CASETYPE == 5) {
                    console.log('that.TROUBLE_SOURCE=' + that.TROUBLE_SOURCE);
                    if ((that.TROUBLE_SOURCE == '' || that.TROUBLE_SOURCE == null) && that.TROUBLE_SOURCE != '0') {
                        that.$toast({
                            text: '请选择故障源！',
                            type: 'wraning',
                            duration: 1500
                        });
                        return false;
                    }
                    if ((that.TROUBLE_TYPE == '' || that.TROUBLE_TYPE == null) && that.TROUBLE_TYPE != '0') {
                        that.$toast({
                            text: '请选择故障类型！',
                            type: 'wraning',
                            duration: 1500
                        });
                        return false;
                    }
                    data.TROUBLE_SOURCE = that.TROUBLE_SOURCE;
                    data.TROUBLE_TYPE = that.TROUBLE_TYPE;
                }
                if (that.process_result == '' || that.process_result == null) {
                    that.$toast({
                        text: '请输入处理结果！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                that.btnDisabled = true;
                updateDetail(data).then(res => {
                    if (res.code == 1 && res.state != 'FAILURE') {
                        this.$toast({
                            text: '保存成功！',
                            type: 'success',
                            duration: 1500
                        })
                    } else {
                        this.$toast({
                            text: res.msg,
                            type: 'wraning',
                            duration: 1500
                        })
                    }
                    setTimeout(function () {
                        that.btnDisabled = false;
                    }, 2500);
                });
            },

            //退单
            tuiDan() {
                this.$confirm('确定要退单吗？', () => {
                    var data = {
                        ghid: this.ghid,
                        token: this.token,
                        caseid: this.caseid,
                        casetype: this.casetype,
                        status: 1
                    }
                    updateDetail(data).then(res => {
                        if (res.code == 1 && res.state != 'FAILURE') {
                            this.$toast({
                                text: '退单成功！',
                                type: 'success',
                                duration: 1500
                            });
                            this.getDetail();
                        } else {
                            this.$toast({
                                text: res.MSG,
                                type: 'wraning',
                                duration: 1500
                            })
                        }
                    });
                }, () => {
                    console.log('这是取消事件');
                })
            },
            //转单
            changePeople() {
                this.getOrgMember();
                this.showPop = true;
            },
            //完单
            endDan() {
                var that = this;
                that.getDetail();
                console.log('process_result=' + that.process_result);
                var data = {
                    ghid: this.ghid,
                    token: this.token,
                    caseid: this.caseid,
                    casetype: this.casetype,
                    process_result: this.process_result,
                    status: 2
                }
                if ((that.item.CASETYPE == 1 || that.item.CASETYPE == 2 || that.item.CASETYPE == 5 || that.item.CASETYPE == 6 || that.item.CASETYPE == 7 || that.item.CASETYPE == 13) && that.item.PUNCH_ADDR == null || this.item.PUNCH_ADDR == '') {
                    that.$toast({
                        text: '请先打卡！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                if (that.item.CASETYPE == 1 || that.item.CASETYPE == 2 || that.item.CASETYPE == 5) {
                    console.log('that.TROUBLE_SOURCE=' + that.TROUBLE_SOURCE);
                    if ((that.TROUBLE_SOURCE == '' || that.TROUBLE_SOURCE == null) && that.TROUBLE_SOURCE != '0') {
                        that.$toast({
                            text: '请选择故障源！',
                            type: 'wraning',
                            duration: 1500
                        });
                        return false;
                    }
                    if ((that.TROUBLE_TYPE == '' || that.TROUBLE_TYPE == null) && that.TROUBLE_TYPE != '0') {
                        that.$toast({
                            text: '请选择故障类型！',
                            type: 'wraning',
                            duration: 1500
                        });
                        return false;
                    }
                    data.TROUBLE_SOURCE = that.TROUBLE_SOURCE;
                    data.TROUBLE_TYPE = that.TROUBLE_TYPE;
                }
                if (that.process_result == '' || that.process_result == null) {
                    that.$toast({
                        text: '请输入处理结果！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                data.status = 4;
                updateDetail(data).then(res => {
                    if (res.code == 1 && res.state != 'FAILURE') {
                        data.status = 2;
                        this.$confirm('确定要完单吗？', () => {
                            updateDetail(data).then(res => {
                                if (res.code == 1 && res.state != 'FAILURE') {
                                    this.$toast({
                                        text: '完单成功！',
                                        type: 'success',
                                        duration: 1500
                                    });
                                    this.getDetail();
                                } else {
                                    this.$toast({
                                        text: res.MSG,
                                        type: 'wraning',
                                        duration: 1500
                                    })
                                }
                            });
                        }, () => {
                            console.log('这是取消事件');
                        })
                    }
                });

            },
            //选择对应的故障源
            selectSource() {
                var that = this;
                console.log('selectSource=' + that.TROUBLE_SOURCE);
                if (that.TROUBLE_SOURCE == 0) {
                    that.typeList = [{id: 0, val: '大空开跳闸'}, {id: 1, val: '复电失败'}, {id: 2, val: '大空开故障'}, {
                        id: 3,
                        val: '电表亮黄灯'
                    }, {id: 4, val: '串户'}, {id: 5, val: '换表'}, {id: 6, val: '其它故障停电'}, {id: 7, val: '其他'}];
                } else {
                    that.typeList = [{id: 8, val: '表下空开跳闸'}, {id: 9, val: '表下空开故障'}, {id: 10, val: '室内空开跳闸'}, {
                        id: 11,
                        val: '室内故障'
                    }, {id: 12, val: '核查用电'}, {id: 13, val: '核查串户'}, {id: 14, val: '其他'}];
                }
            },
            //选择对应的组织
            selectOrg() {
                this.memList = this.orgItem.member;
            },
            //选择对应的人员
            selectMem() {
                console.log('this.rel_ghid' + this.rel_ghid);
            },
            //取消时隐藏弹窗
            hidePop() {
                this.showPop = false;
            },
            //提交弹出页
            submitPop() {
                var data = {
                    ghid: this.ghid,
                    token: this.token,
                    caseid: this.caseid,
                    casetype: this.casetype,
                    status: 3,
                    transfer_ghid: this.rel_ghid,
                }
                updateDetail(data).then(res => {
                    if (res.code == 1 && res.state != 'FAILURE') {
                        this.showPop = false;
                        this.$toast({
                            text: '转派成功！',
                            type: 'success',
                            duration: 1500
                        });
                        this.getDetail();
                    } else {
                        this.showPop = false;
                        this.$toast({
                            text: res.msg,
                            type: 'wraning',
                            duration: 2500
                        })
                    }
                });
            },
            //返回上一页
            back(){
                var that = this;
                if (that.fromHome == 1) {
                    that.$router.push({
                        name: 'home'
                    })
                } else {
                    that.$router.push({
                        name: 'order',
                        query: {
                            status: that.preStatus,
                            keyword: that.keyword
                        }
                    })
                }
//                this.$router.go(-1);//返回上一层
            },
        },
        filters: {
            formatDate: function (value) {
                return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
            },

            formatTime: function (value) {
                return value.substring(0, 2) + ':' + value.substring(2, 4) + ':' + value.substring(4, 6)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";

    .imgadd{flex: 1;display: grid;grid-template-columns: repeat(3, 1fr);gap: 20px;margin-left: 10px;}
    .imgadd>div{position: relative;}

    .imgadd img{width: 100%; height: auto;position: relative;  z-index:10; min-height: 60px;}
    .imgadd img.close{ width: 20px;height: 20px!important;min-height: 20px!important; position: absolute; top:-5px;right:-5px; background: #f00; border-radius: 100px;z-index: 20;opacity: 1;}

    .imgadd button{border: 1px #eee solid;border-radius: 4px; background: #FAFAFA;display: flex;align-items: center
    ; justify-content: center;}
    .imgadd button img{width: 30px; height: 30px!important;min-height: 30px!important;}

</style>
