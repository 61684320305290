<!-- header -->
<template>
  <div id="big_box" style="background: #fff;">
    <CommonHeader title="个人登录" isBack="false"/>
    <div id="sention">
      <!-- bg -->
      <div class="login_bg"><img src="@/assets/images/img_loginbg.png"></div>
      <!-- 登录 -->
      <div class="login_box bg-white">
        <ul>
          <li class="mob">
            <input type="text" v-model="ghid" placeholder="请输入工号">
          </li>
          <li class="pwd">
            <input type="password" v-model="pwd" placeholder="请输入密码 ">
          </li>
          <li>
            <button @click="login" id="btnLogin">登录</button>
          </li>
        </ul>
        <!-- 其它登录方式 -->
        <div class="wechat">
          <span><i></i>其它登录方式<i></i></span>
          <button @click="wxLogin"><img src="@/assets/images/icon_wechat.png"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    import { setValue,getValue } from '@/utils/auth'
    import { login, getOpenid} from "@/api/login";
//    import {  getOpenid} from "@/api/login";
    export default {
        name: 'login',
        components: {
            CommonHeader
        },
        data () {
            return {
                code: '',
                 // ghid: '8890',
                 // pwd: 'hjzx1234',
                 // openid: 'o8t_i6E_0LX9V-333',
              ghid: '',
              pwd: '',
              openid: 'fff22dddddddd2111',
                headImg: '',
                winUrl: '',
                msg: 'Welcome to Your Vue.js App',
                appid: process.env.VUE_APP_APPID
            }
        },
        mounted () {
			var ua = navigator.userAgent.toLocaleLowerCase();
			// 判断是否为IE(第一个是正常的IE，第二个是Edge，第三个是IE11)
			
            window.localStorage.removeItem('openId');
            var ghid = getValue('ghid');
            var pwd = getValue('pwd');
            if(ghid!=undefined && ghid != '') {
                this.ghid = ghid;
            }
            if(pwd!=undefined && pwd != '') {
                this.pwd = pwd;
            }
            if (getValue('openId') == undefined || getValue('openId')=='') { // 如果缓存localStorage中没有微信openId，则需用code去后台获取
                // if(process.env.VUE_APP_FLAG == '1') {
				if(ua.indexOf('micromessenger') >= 0){
                    console.log('从URL获取Code');
                    this.getOpenidFromUrl()
                }
				else{
					document.getElementById("btnLogin").innerText  = "调试模式登录";
				}
            } else {
                console.log(222);
            }
        },
        methods: {
            getOpenidFromUrl () { // 非静默授权，第一次有弹框
                var that = this;
                that.code = ''
                var local = window.location.href // 获取页面url
                that.code = that.getUrlCode().code // 截取code
                if (that.code == null || that.code === '') { // 如果没有code，则去请求
                    console.log(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base`)
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo`
                } else {
                    getOpenid({code: that.code}).then(res =>{
                        console.log(res);
                        that.openid = res.openid;
                        that.headImg = res.headimgurl;
                        setValue('headImg', that.headImg);
                    });
                }
            },
            //登录
            login() {
                var that = this;
                var data = {
                    ghid: that.ghid,
                    pwd: that.pwd,
                    openid: that.openid,
                    headImg: that.headImg
                }

                console.log('data'+data);
                login(data).then(res =>{
                    if(res.code == 1) {
                        setValue('token', res.token);
                        setValue('ghid', res.ghid);
                        setValue('pwd', this.pwd);
                        setValue('openid', this.openid);
                        setValue('isLogin', true);
                        setValue('userType', 'user');//当userType为user时，代表使用人员，为youke时代表普通游客
                        this.$router.push({
                            name:'home',
                            query: {
                                fromLogin: 1
                            }
                        })
                    }else {
                        this.$toast({
                            text: res.msg,
                            type: 'wraning',
                            duration: 2500
                        })
                    }
                });
            },

            // 截取url中的code方法
            getUrlCode () {
                var url = location.search
                console.log('url=='+url)
                this.winUrl = url
                var theRequest = new Object()
                if (url.indexOf('?') != -1 ) {
                    var str = url.substr(1)
                    var strs = str.split('&')
                    for (var i = 0; i < strs.length; i++) {
                        theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
                    }
                }
                return theRequest
            },
            //微信授权登录
            wxLogin() {
                this.login();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
  @import "../assets/css/bootstrap.min.css";
  @import "../assets/css/style.css";
  @import "../assets/css/swiper-bundle.min.css";
</style>
