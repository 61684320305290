<!-- header -->
<template>
    <div id="big_box">
        <CommonHeader title="房间管理" isBack="1"/>
        <!-- so -->
        <div class="so mb-3">
            <!--<input type="text" v-model="keyword" placeholder="请输入关键字">-->
        </div>


        <!-- 中间内容区域  高度自适应 -->
        <div id="sention">
            <div class="no_data" v-if="list.length == 0">
                <img src="@/assets/images/no_data.png">
                <span>暂无数据</span>
            </div>
            <!-- house_box -->
            <a class="house_box bg-white" href="#" v-for="item in list" :key="item.user_num">
                <h5>
                    <span>房主：{{item.name}}</span>
                </h5>
                <ul>
                    <!--<li class="hz"><i></i><span>户主：{{item.name}}</span></li>-->
                    <li class="hh"><i></i><span>户号：{{item.user_num}}</span></li>
                    <li class="dz"><i></i><span>地址：{{item.address}}</span></li>
                </ul>
            </a>


            <!--layer-->
            <div class="mask-box" v-if="showPop">
                <div class="order_layer">

                    <div class="ipt">
                        <input type="text" v-model="mobile" placeholder="请输入手机号">
                    </div>
                    <div class="ipt yzm">
                        <span class="" v-show="verification" @click="handleClick">获取验证码</span>
                        <span class="active" v-show="!verification">{{timer}}秒</span>
                        <input type="text" v-model="code" placeholder="请输入验证码">
                    </div>

                    <div class="btns">
                        <button class="cancel" @click="hidePop">取消</button>
                        <button class="seccess" @click="submitPop">确定</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonHeader from '../components/CommonHeader.vue'
    import { send } from "@/api/login";
    import axios from 'axios';


    export default {
        name: 'room',
        components: {
            CommonHeader
        },
        data () {
            return {
                showPop: true,
                mobile: '',
                keyword: '',
                code: '',
                smsCode: '',
                verification: true,    //通过v-show控制显示获取还是倒计时
                timer: 60,      //定义初始时间为60s
                prefix: process.env.VUE_APP_BASE_API,
                list: []
            }
        },
        mounted () {


        },
        methods: {
            handleClick(){
                var that = this;
                if (that.mobile == '') {
                    that.$toast({
                        text: '请输入手机号！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                if (!regMobile.test(that.mobile)) {
                    that.$toast({
                        text: '手机号格式不正确！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                send({mobile:that.mobile}).then(res => {
                    that.smsCode = res.smsCode;
                });
                that.verification = false;      //点击button改变v-show的状态
                let auth_timer = setInterval(() => {  //定时器设置每秒递减
                    that.timer--;        //递减时间
                    if (that.timer <= 0) {
                        that.verification = true;    //60s时间结束还原v-show状态并清除定时器
                        that.smsCode = '';
                        clearInterval(auth_timer)
                    }
                }, 1000)
            },
            //取消时隐藏弹窗
            hidePop() {
                this.showPop = false;
            },
            //提交弹出页
            submitPop() {
                var that = this;
                if (that.mobile == '') {
                    that.$toast({
                        text: '请输入手机号！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                if (!regMobile.test(that.mobile)) {
                    that.$toast({
                        text: '手机号格式不正确！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                if (that.code == '') {
                    that.$toast({
                        text: '请输入验证码！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                if (that.code != that.smsCode) {
                    that.$toast({
                        text: '验证码不正确！',
                        type: 'wraning',
                        duration: 1500
                    });
                    return false;
                }
                axios.post(this.prefix+ '/case/search/room_list',{
                    keyword:that.mobile
                })
                .then(function(res){
                    var data = res.data.data;
                    console.log('res.data==='+JSON.stringify(res.data));
                    that.showPop = false;
                    if(data.length == 0 || data[0].name=='') {
                        that.list = [];
                    }else {
                        that.list = data;
                    }
                });
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
    @import "../assets/css/bootstrap.min.css";
    @import "../assets/css/style.css";
    @import "../assets/css/swiper-bundle.min.css";
</style>
