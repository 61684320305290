<!-- header -->
<template>
	<div>
        <CommonHeader title="用户组列表" isBack="1"/>
		<!-- tab -->
		<div class="tab bg-white">
            <span v-bind:class="status == 0 ? 'active' : ''" @click="tabTap(0)">未处理</span>
            <span v-bind:class="status == 1 ? 'active' : ''" @click="tabTap(1)">已挂起</span>
            <span v-bind:class="status == 2 ? 'active' : ''" @click="tabTap(2)">已完成</span>
		</div>

		<!-- so -->
		<div class="so mb-3">
			<input type="text" placeholder="请输入关键字" v-model="keyword" @keypress="searchList">
		</div>
		<!-- yhz_list -->
		<div class="yhz_list">
			<ul>
				<li v-for="item in memList" :key="item.index" >
					<select v-model="item.STATUS">
						<option value="未处理">未处理</option>
						<option value="挂起中">挂起中</option>
						<option value="已完成">已完成</option>
					</select>
					<p>
						<span class="t">姓名：</span>
						<span class="s">{{item.UNAME}}</span>
					</p>
					<p>
						<span class="t">手机：</span>
						<span class="s">{{item.PHONE}}</span>
					</p>
					<p>
						<span class="t">地址：</span>
						<span class="s">{{item.ADDRESS}}</span>
					</p>
				</li>				
			</ul>
		</div>
		<!-- footer -->
		<div class="footer_btn">
			<button class="save" @click="save">保存</button>
			<button class="back" @click="back">返回</button>
		</div>
	</div>
</template>

<script>
	import CommonHeader from '../components/CommonHeader.vue'
    import {getValue} from '@/utils/auth'
    import {getDetail,updateDetailGas} from "@/api/order";

    export default {
        name: 'user_group',
        components: {
            CommonHeader
        },
        data () {
            return {
                keyword: '',
				ghid: getValue('ghid'),
				status: 0,
                caseid: '',
                casetype: '1',
				groups: '',
                token: getValue('token'),
                memList: [],
				oldList: [],
            }
        },
        mounted () {
            var that = this;
            that.caseid = that.$router.history.current.query.CASEID;
            that.casetype = that.$router.history.current.query.CASETYPE;
			that.getDetail();
        },
        methods: {
            tabTap(idx) {
                this.status = idx
                this.getDetail();
            },
            //检索事件
            searchList(event) {
                if (event.keyCode == 13) { //如果按的是enter键 13是enter
                    this.getDetail()
                }
            },
			//获取工单详情
			getDetail() {
				var data = {
					ghid: this.ghid,
					caseid: this.caseid,
					casetype: this.casetype,
					token: this.token
				}
				console.log("开始获取工单详情===" + this.caseid)
				getDetail(data).then(res => {
					if (res.code == 1) {
						this.item = res.data;
						var INSPECTED_USERS = this.item.INSPECTED_USERS;
						INSPECTED_USERS = INSPECTED_USERS.replace('姓名','UNAME');
						INSPECTED_USERS = INSPECTED_USERS.replace('手机','PHONE');
						INSPECTED_USERS = INSPECTED_USERS.replace('地址','ADDRESS');
						INSPECTED_USERS = INSPECTED_USERS.replace('状态','STATUS');
						var temp = [];
						var arr = INSPECTED_USERS.split('\n');
						for(var k=0;k<arr.length;k++) {
							var narr = arr[k].split(';');
							var obj = {
								UNAME: narr[0].split(':')[1],
								PHONE: narr[1].split(':')[1],
								ADDRESS: narr[2].split(':')[1],
								STATUS: narr[3].split(':')[1],
							}
							temp.push(obj);
						}
						this.oldList = temp;
						if(this.keyword == '') {
							var result1 = [];
							for(var j=0;j<temp.length;j++) {
								var sflag1 = (this.status == 0 && (temp[j].STATUS == undefined || temp[j].STATUS=='未处理')) || (this.status == 1 && temp[j].STATUS=='挂起中') || (this.status == 2 && temp[j].STATUS=='已完成');
								if(sflag1) {
									result1.push(temp[j]);
								}
							}
							this.memList = result1;
						}else {
							var result = [];
							for(var i=0;i<temp.length;i++) {
								var flag = temp[i].UNAME.indexOf(this.keyword)>-1 || temp[i].PHONE.indexOf(this.keyword)>-1 || temp[i].ADDRESS.indexOf(this.keyword)>-1;
								var sflag = (this.status == 0 && (temp[i].STATUS == undefined || temp[i].STATUS=='未处理')) || (this.status == 1 && temp[i].STATUS=='挂起中') || (this.status == 2 && temp[i].STATUS=='已完成');
								if(flag && sflag) {
									result.push(temp[i]);
								}
							}
							this.memList = result;
						}
					}
				});
			},
			//保存
			save() {
				var list = this.oldList;
				for(var k=0;k<list.length;k++) {
					for(var j=0;j<this.memList.length;j++) {
						if(list[k].UNAME==this.memList[j].UNAME && list[k].PHONE==this.memList[j].PHONE && list[k].ADDRESS==this.memList[j].ADDRESS) {
							list[k].STATUS = this.memList[j].STATUS;
							break;
						}
					}
				}
				var str = '{';							
				for(var i=0;i<list.length;i++) {
					if(i == (list.length-1)) {
						str = str + '姓名:'+list[i].UNAME+';手机号:'+list[i].PHONE+';地址:'+list[i].ADDRESS+';状态:'+list[i].STATUS+';';
					}else {
						str = str + '姓名:'+list[i].UNAME+';手机号:'+list[i].PHONE+';地址:'+list[i].ADDRESS+';状态:'+list[i].STATUS+';\n';
					}
				}
				str = str + '}';
				var data = {
					ghid: this.ghid,
					token: this.token,
					caseid: this.caseid,
					casetype: this.casetype,
					transfer_ghid: "",
					changedata: [{INSPECTED_USERS:str}],
					status: 4,
				};
				updateDetailGas(data).then(res => {
					if (res.code == 1 && res.state != 'FAILURE') {
						this.$toast({
							text: '保存成功！',
							type: 'success',
							duration: 1500
						})
						this.getDetail();
					} else {
						this.$toast({
							text: res.msg,
							type: 'wraning',
							duration: 1500
						})
					}
				});
			},
            //返回上一页
            back(){
               this.$router.go(-1);//返回上一层
            },
           
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
	@import "../assets/css/bootstrap.min.css";
	@import "../assets/css/style.css";


	/* .yhz_list */
	.yhz_list {
		margin: 0 16px 60px 16px;
		display: block;
	}

	.yhz_list li {
		background: #fff;
		padding: 16px;
		box-sizing: border-box;
		border-radius: 4px;
		position: relative;
		margin-bottom: 16px;
	}

	.yhz_list li p {
		display: flex;
	}

	.yhz_list li p span.t {
		width: 50px;
		display: block;
	}

	.yhz_list li p span.s {
		flex: 1;
	}

	.yhz_list select {
		position: absolute;
		top: 16px;
		right: 16px;
		font-size: 14px;
		border: 1px #ccc solid;
		padding: 4px 8px;
		box-sizing: border-box;
	}


	/* footer_btn */
	.footer_btn {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
	}
</style>
s
